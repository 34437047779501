import { menuItemListsConstants } from "../_constants";

const initialState = {};

//login reducer
export const get_menu_item_lists_user_reducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case menuItemListsConstants.GET_MENU_ITEM_LISTS_USER_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case menuItemListsConstants.GET_MENU_ITEM_LISTS_USER_ACTION_SUCCESS:
      return {
        loggedIn: true,
        menuItemListData: action.payload,
      };
    case menuItemListsConstants.GET_MENU_ITEM_LISTS_USER_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};
