import React, { useState } from "react";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function AddOffer() {

  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="staff-management" />
      <div className="dashboard-main-content staff-listing-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="form-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-10 mb-4">
                <h3 className="text-primary">Add New Offer</h3>
              </div>
            </div>
            <Form>
              <div className="row justify-content-center">
                <div className="col-lg-10 ">


                  <div className="bg-white shadow rounded-lg p-3 mb-4">
                    <div class="border-bottom pb-3 mb-4">
                      <h5 className="text-primary">Offer Information</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Offer Id
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Job Id"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Offer Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Offer Code"
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Offer Type
                          </Form.Label>
                          <Form.Select
                            as="select"
                          >
                            <option value="">Select</option>
                            <option value="">Restricted Discount</option>
                            <option value="">Flat Discount</option>
                          </Form.Select>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Restricted Amount
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Restricted Amount"
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Discount Percentage
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Discount Percentage"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label>Activate Offer</Form.Label>
                          <Form.Check
                            type="switch"
                            id="EnableDiscount"
                            label="On/Off"
                            defaultChecked="checked"
                          />{" "}
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Start Date
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="Start Date"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            End Date
                          </Form.Label>
                          <Form.Control
                            type="date"
                            placeholder="End Date"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-12">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Description
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="Enter Description..."
                          />
                        </Form.Group>
                      </div>
                     
                    </div>

                    <div className="col-12 action pt-3 border-top d-flex align-items-center justify-content-end">
                      <button className="btn btn-secondary ">
                        Submit
                      </button>
                      <button className="btn btn-primary ms-3">
                        Delete
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
