import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { get_category_lists_user_action } from "../_redux";

function useCategoryList() {
  const [categoryList, setCategoryList] = useState();

  const { categoryListData } = useSelector(
    (state) => state.get_category_lists_user_reducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (categoryListData?.data) {
      setCategoryList(categoryListData?.data);
    }
  }, [categoryListData]);

  useEffect(() => {
    callApi();
  }, []);

  //call api for categorylist
  const callApi = async () => {
    await dispatch(get_category_lists_user_action());
  };

  const onViewCategoryClick = (id) => {
    navigate(routes.private.viewFoodCategoryList.replace(":id", id));
  };

  const onCategoryEditClick = (id) => {
    navigate(routes.private.editFoodCategory.replace(":id", id))
  }

  

  return {
    categoryList,
    onViewCategoryClick,
    onCategoryEditClick,
  };
}

export default useCategoryList;
