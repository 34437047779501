import React, { useState } from "react";

function useQRCodeManagement(props) {
  const [tableDetails, setTableDetails] = useState();

  const [deletePopupModal, setDeletePopupModal] = useState(false);
  const [viewTableModal, setViewTableModal] = useState(false);

  const handleDeletePopupModalShow = () =>
    setDeletePopupModal(!deletePopupModal);

  const handleViewTableModalShow = (value) => {
    if (value) {
      setTableDetails({ number: value });
    }
    setViewTableModal(!viewTableModal);
  };

  const getQrCodeValue = () => {
    if (tableDetails?.number) {
      let url = `www.menulao.com/food-menu/${tableDetails?.number}`;

      return url;
    }
  };

  return {
    tableDetails,
    deletePopupModal,
    viewTableModal,
    handleDeletePopupModalShow,
    handleViewTableModalShow,
    getQrCodeValue,
  };
}

export default useQRCodeManagement;
