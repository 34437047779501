import axiosInstance from "../../../../_config/AxiosInstance";
import config from "../../../../_config/config";
import { categoryListsConstants } from "../_constants";

// login
export const get_category_lists_user_action = (data) => async (dispatch) => {
  dispatch({
    type: categoryListsConstants.GET_CATEGORY_LISTS_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.category.categoryLists);
    dispatch({
      type: categoryListsConstants.GET_CATEGORY_LISTS_USER_ACTION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: categoryListsConstants.GET_CATEGORY_LISTS_USER_ACTION_FAILURE,
      payload: e,
    });
    return e;
  }
};
