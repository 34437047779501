import React, { useState } from "react";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function AddInventory() {

  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="staff-management" />
      <div className="dashboard-main-content staff-listing-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="form-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-10 mb-4">
                <h3 className="text-primary">Add New Inventory</h3>
              </div>
            </div>
            <Form>
              <div className="row justify-content-center">
                <div className="col-lg-10 ">
                  <div className="bg-white shadow rounded-lg p-3 mb-4">
                    <div class="border-bottom pb-3 mb-4">
                      <h5 className="text-primary">Inventory Details </h5>
                      <p className="mb-0">An inventory is a physical location where you keep your goods. Inventory can be your outlet or a separate warehouse..
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Inventory Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Job Id"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-12">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Description (Optional)
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="Enter Description..."
                          />
                        </Form.Group>
                      </div>


                    </div>

                  </div>
                  <div className="bg-white shadow rounded-lg p-3 mb-4">
                    <div class="border-bottom pb-3 mb-4">
                      <h5 className="text-primary">Inventory Items </h5>

                    </div>
                    <div className="row">
                      <div className="col-lg-5">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Item Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Item Name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-5">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Quantity to Add
                          </Form.Label>
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Quantity"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-2">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2 d-block">
                            &nbsp;
                          </Form.Label>
                          <button className="btn btn-primary btn-sm mt-1">Delete</button>
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-12 action pt-3 border-top d-flex align-items-center justify-content-end">
                      <button className="btn btn-secondary ">
                        Submit
                      </button>
                      <button className="btn btn-primary ms-3">
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
