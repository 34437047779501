import React from "react";
import { Dropdown } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faEnvelope,
  faGears,
  faPowerOff,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
export default function Header(props) {
  return (
    <header className="bg-white  d-flex align-items-center">
     
      <div className="header-actions ms-auto d-flex align-items-center">
        <Dropdown className="header-messages">
          <Dropdown.Toggle variant="" className="no-arrow btn-indicator">
            <FontAwesomeIcon icon={faEnvelope} />
            <span className="count-indicator bg-danger text-white">23</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className="messages-list">
              <div className="d-flex message-box">
                <div className="user-img">
                  <img src={"/assets/images/avatar.png"} alt="user" />
                </div>
                <div className="content font-small">
                  <p className="message-text font-weight-semi">
                    Hey! How are you ?
                  </p>
                  <p className="date text-muted">80 hours Ago</p>
                </div>
              </div>

              <div className="d-flex message-box">
                <div className="user-img">
                  <img src={"/assets/images/avatar.png"} alt="user" />
                </div>
                <div className="content font-small">
                  <p className="message-text font-weight-semi">
                    Hey! How are you ?
                  </p>
                  <p className="date text-muted">80 hours Ago</p>
                </div>
              </div>

              <div className="d-flex message-box">
                <div className="user-img">
                  <img src={"/assets/images/avatar.png"} alt="user" />
                </div>
                <div className="content font-small">
                  <p className="message-text font-weight-semi">
                    Hey! How are you ?
                  </p>
                  <p className="date text-muted">80 hours Ago</p>
                </div>
              </div>

              <div className="d-flex message-box">
                <div className="user-img">
                  <img src={"/assets/images/avatar.png"} alt="user" />
                </div>
                <div className="content font-small">
                  <p className="message-text font-weight-semi">
                    Hey! How are you ?
                  </p>
                  <p className="date text-muted">80 hours Ago</p>
                </div>
              </div>

              <div className="d-flex message-box">
                <div className="user-img">
                  <img src={"/assets/images/avatar.png"} alt="user" />
                </div>
                <div className="content font-small">
                  <p className="message-text font-weight-semi">
                    Hey! How are you ?
                  </p>
                  <p className="date text-muted">80 hours Ago</p>
                </div>
              </div>

              <div className="d-flex message-box">
                <div className="user-img">
                  <img src={"/assets/images/avatar.png"} alt="user" />
                </div>
                <div className="content font-small">
                  <p className="message-text font-weight-semi">
                    Hey! How are you ?
                  </p>
                  <p className="date text-muted">80 hours Ago</p>
                </div>
              </div>
            </div>
            <button className="btn btn-primary btn-block w-100">
              View All
            </button>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="header-notifications">
          <Dropdown.Toggle variant="" className="no-arrow btn-indicator">
            <FontAwesomeIcon icon={faBell} />
            <span className="count-indicator bg-danger text-white">3</span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className="notifications-list">
              <div className="d-flex notification-box">
                <div className="icon">
                  <img
                    src={"/assets/images/notification-icons/add.png"}
                    alt="order placed"
                  />
                </div>
                <div className="content font-small">
                  <p className="notication-text font-weight-semi">
                    Table No.1 has placed an order
                  </p>
                  <p className="time text-muted">10 Min ago</p>
                </div>
              </div>
              <div className="d-flex notification-box">
                <div className="icon">
                  <img
                    src={"/assets/images/notification-icons/booking.png"}
                    alt="booking"
                  />
                </div>
                <div className="content font-small">
                  <p className="notication-text font-weight-semi">
                    Table No.4 has booking request received
                  </p>
                  <p className="time text-muted">10 Min ago</p>
                </div>
              </div>
              <div className="d-flex notification-box">
                <div className="icon">
                  <img
                    src={
                      "/assets/images/notification-icons/servant-outline.png"
                    }
                    alt="servant"
                  />
                </div>
                <div className="content font-small">
                  <p className="notication-text font-weight-semi">
                    Table No.3 need an assistance
                  </p>
                  <p className="time text-muted">10 Min ago</p>
                </div>
              </div>
              <div className="d-flex notification-box">
                <div className="icon">
                  <img
                    src={"/assets/images/notification-icons/clock.png"}
                    alt="clock"
                  />
                </div>
                <div className="content font-small">
                  <p className="notication-text font-weight-semi">
                    Reminder: Table No.4 has been waiting for the order
                  </p>
                  <p className="time text-muted">10 Min ago</p>
                </div>
              </div>

              <button className="btn btn-primary btn-block w-100">
                View All Notifications
              </button>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown className="header-user">
          <Dropdown.Toggle variant="" className="no-arrow btn-indicator">
            <div className="user-img">
              <img src={"/assets/images/avatar.png"} alt="user" />
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <div className="user-actions-list">
              <h5 className="text-white d-flex align-items-center">
                <div className="rest-icon">
                  <img src={"/assets/images/hot-pot.png"} alt="user" />
                </div>
                Chilli King
              </h5>
              <ul>
                <li>
                  <p>
                    <span className="icon">
                      <FontAwesomeIcon icon={faUserAlt} />
                    </span>
                    My Profile
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </span>
                    My Inbox
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon">
                      <FontAwesomeIcon icon={faGears} />
                    </span>
                    Setting
                  </p>
                </li>
                <li>
                  <p>
                    <span className="icon">
                      <FontAwesomeIcon icon={faPowerOff} />
                    </span>
                    Log Out
                  </p>
                </li>
              </ul>
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}
