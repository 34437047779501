
import config from "../../../../_config/config";
import axiosInstance from "../../../../_config/AxiosInstance";
import { authConstants } from "../_constants";


export const signin_user_action = (data) => async (dispatch) => {
  dispatch({
    type: authConstants.LOGIN_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.post(config.auth.signin, data);
    if (response.status) {
      let data = response?.data;
      localStorage.setItem("menulao-user", JSON.stringify(data));
      dispatch({
        type: authConstants.LOGIN_USER_ACTION_SUCCESS,
        payload: response.data,
      });
      return response.data;
    } else {
      return false;
    }
  } catch (e) {
    dispatch({
      type: authConstants.LOGIN_USER_ACTION_FAILURE,
      payload: e?.msg,
    });

    return e;
  }
};