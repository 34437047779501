import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { Tabs, Tab, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
export default function Dashboard() {
  const [viewRatingsModal, setViewRatingsModal] = useState(false);
  const [viewPendingAssistanceModal, setViewPendingAssistanceModal] = useState(false);
  const [viewPendingOrdersModal, setViewPendingOrdersModal] = useState(false);
  const [viewAvailableTablesModal, setViewAvailableTablesModal] = useState(false);
  const viewRatingsModalHandler = () => {
    setViewRatingsModal(!viewRatingsModal);
  };
  const viewPendingAssistanceModalHandler = () => {
    setViewPendingAssistanceModal(!viewPendingAssistanceModal);
  };
  const viewPendingOrdersModalHandler = () => {
    setViewPendingOrdersModal(!viewPendingOrdersModal);
  };
  const viewAvailableTablesModalHandler = () => {
    setViewAvailableTablesModal(!viewAvailableTablesModal);
  };
  return (
    <div className="admin-dashboard-section dashboard-main-wrapper">
      <Sidebar activeMenuItem="dashboard" />
      <div className="dashboard-main-content">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <h2 className="mb-4">Dashboard</h2>
          <div className="row">
            <div className="col-4">
              <div className="bg-white basic-analytic-box shadow mb-4 p-3">
                <div className="img-box">
                  <img src="./assets/images/dinner-table.png" alt="total Tables" />
                </div>
                <div className="cursor-pointer" onClick={viewAvailableTablesModalHandler}>
                  <h3 className="text-secondary cursor-pointer">10</h3>
                  <h5 className="text-primary">Total Tables Available</h5>
                </div>

              </div>
            </div>
            <div className="col-4">
              <div className="bg-white basic-analytic-box shadow mb-4 p-3">
                <div className="img-box">
                  <img src="./assets/images/waiter.png" alt="total Assistance" />
                </div>
                <div className="cursor-pointer" onClick={viewPendingAssistanceModalHandler}>
                  <h3 className="text-secondary" >04</h3>
                  <h5 className="text-primary">Pending Assistance</h5>
                </div>

              </div>
            </div>
            <div className="col-4">
              <div className="bg-white basic-analytic-box shadow mb-4 p-3">
                <div className="img-box">
                  <img src="./assets/images/file.png" alt="Pending Orders" />
                </div>
                <div className="cursor-pointer" onClick={viewPendingOrdersModalHandler}>
                  <h3 className="text-secondary cursor-pointer">50</h3>
                  <h5 className="text-primary">Pending Orders</h5>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="bg-white basic-analytic-box shadow mb-4 p-3">
                <div className="img-box">
                  <img src="./assets/images/food-category.png" alt="total menus" />
                </div>
                <h3 className="text-secondary">125</h3>
                <h5 className="text-primary">Total Menus Items</h5>
              </div>
            </div>
            <div className="col-4">
              <div className="bg-white basic-analytic-box shadow mb-4 p-3">
                <div className="img-box">
                  <img src="./assets/images/rupee.png" alt="total revenue" />
                </div>
                <h3 className="text-secondary">₹2000</h3>
                <h5 className="text-primary">Today's Revenue</h5>
              </div>
            </div>
            <div className="col-4">
              <div className="bg-white basic-analytic-box shadow mb-4 p-3">
                <div className="img-box">
                  <img src="./assets/images/note.png" alt="total Orders" />
                </div>
                <h3 className="text-secondary">40</h3>
                <h5 className="text-primary">Todays Orders</h5>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="top-selling-items bg-white p-3">
                <h4 className="pb-2 mb-3 border-bottom">Most Selling Items</h4>
                <div className="custom-tabs-type-2">
                  <Tabs
                    defaultActiveKey="Today"

                    className="mb-3"
                    justify
                  >
                    <Tab eventKey="Today" title="Today">
                      <div className="top-selling-items-list">
                        <div className="top-selling-item">
                          <div className="ranking">
                            #1
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #2
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #3
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/sandwich.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Sandwich</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sandwiches</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #4
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/burgers.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Burger</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Burgers</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #5
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #6
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #7
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/sandwich.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Sandwich</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sandwiches</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #8
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/burgers.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Burger</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Burgers</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #9
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #10
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                      </div>
                      <div className="border-top text-center pt-3">
                        <button className="btn btn-secondary">View All </button>
                      </div>
                    </Tab>
                    <Tab eventKey="Weekly" title="Weekly">
                      <div className="top-selling-items-list">
                        <div className="top-selling-item">
                          <div className="ranking">
                            #1
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #2
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #3
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/sandwich.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Sandwich</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sandwiches</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #4
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/burgers.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Burger</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Burgers</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #5
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #6
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #7
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/sandwich.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Sandwich</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sandwiches</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #8
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/burgers.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Burger</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Burgers</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #9
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #10
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                      </div>
                      <div className="border-top text-center pt-3">
                        <button className="btn btn-secondary">View All </button>
                      </div>
                    </Tab>
                    <Tab eventKey="Monthly" title="Monthly">
                      <div className="top-selling-items-list">
                        <div className="top-selling-item">
                          <div className="ranking">
                            #1
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #2
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #3
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/sandwich.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Sandwich</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sandwiches</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #4
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/burgers.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Burger</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Burgers</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #5
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #6
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #7
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/sandwich.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Sandwich</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sandwiches</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #8
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/burgers.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Chicken Burger</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Burgers</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #9
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/curries.jpg" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mashroom Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Curries</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                        <div className="top-selling-item">
                          <div className="ranking">
                            #10
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/crab-legs-img.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <span className="me-2 food-type">
                                <img src="./assets/images/non-veg.png" alt="veg" />
                              </span>
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Continental Crabs Curry</h5>
                              <h6 className="ml-auto">₹200</h6>
                            </div>
                            <h6 className="text-secondary cursor-pointer">Sea Food</h6>
                            <h6>Total Orders: 250</h6>
                          </div>
                        </div>
                      </div>
                      <div className="border-top text-center pt-3">
                        <button className="btn btn-secondary">View All </button>
                      </div>
                    </Tab>

                  </Tabs>
                </div>
              </div>


            </div>

            <div className="col-lg-6">
              <div className="top-performing-waiters bg-white p-3">
                <h4 className="pb-2 mb-3 border-bottom">Top Performing Waiters</h4>
                <div className="custom-tabs-type-2">
                  <Tabs
                    defaultActiveKey="Today"
                    className="mb-3"
                    justify
                  >
                    <Tab eventKey="Today" title="Today">
                      <div className="top-performing-waiters-list">
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #1
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/avatar2.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mukesh Negi</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>

                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #2
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/user.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Aditya Parmar</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #3
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Rohit Kumar</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #4
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/user.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Kaushal Sharma</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #5
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Govind Singh</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #6
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/avatar2.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Prakash Sharma</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #7
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Sumit Yadav</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="border-top text-center pt-3">
                        <button className="btn btn-secondary">View All </button>
                      </div>
                    </Tab>
                    <Tab eventKey="Weekly" title="Weekly">
                      <div className="top-performing-waiters-list">
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #1
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/avatar2.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mukesh Negi</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>

                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #2
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/user.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Aditya Parmar</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #3
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Rohit Kumar</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #4
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/user.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Kaushal Sharma</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #5
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Govind Singh</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #6
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/avatar2.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Prakash Sharma</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #7
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Sumit Yadav</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="border-top text-center pt-3">
                        <button className="btn btn-secondary">View All </button>
                      </div>
                    </Tab>
                    <Tab eventKey="Monthly" title="Monthly">
                      <div className="top-performing-waiters-list">
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #1
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/avatar2.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Mukesh Negi</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>

                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #2
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/user.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Aditya Parmar</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #3
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Rohit Kumar</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #4
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/user.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Kaushal Sharma</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #5
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Govind Singh</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #6
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/avatar2.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Prakash Sharma</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>
                        <div className="top-performing-waiter">
                          <div className="ranking">
                            #7
                          </div>
                          <div className="item-img">
                            <img src="./assets/images/woman.png" alt="food" />
                          </div>
                          <div className="content flex-grow-1">
                            <div className="text-primary d-flex align-items-start">
                              <h5 className="name flex-grow-1 me-2 cursor-pointer">Sumit Yadav</h5>

                            </div>
                            <h6>Total Orders Served: 250</h6>
                            <div className="d-flex align-items-center mt-1">
                              <span className="font-weight-semi">Overall Ratings:</span>
                              <div className="ms-2 span text-white px-2 rounded-pill justify-content-center d-flex align-items-center bg-success rating">
                                <FontAwesomeIcon icon={faStar} className="fs-small" />
                                <span className="ms-2 font-weight-semi">5.0</span>
                              </div>
                              <span className="text-underline text-primary ms-1 cursor-pointer"
                                onClick={viewRatingsModalHandler}>35 Reviews</span>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="border-top text-center pt-3">
                        <button className="btn btn-secondary">View All </button>
                      </div>
                    </Tab>

                  </Tabs>
                </div>
              </div>


            </div>

          </div>

          {/* <div className="col-lg-6">

              top performers (Waiters)

            </div>

            customer rate


            first time , returning */}
        </div>
      </div>


      {/* view ratings popup  */}
      <Modal
        size="md"
        show={viewRatingsModal}
        onHide={viewRatingsModalHandler}
        centered
        className="review-modal"
      >
        <Modal.Header closeButton className="border-bottom-0 ">
          <Modal.Title>
            <h4 className="text-primary">
              Customers Ratings{" "}
              <span className="font-small text-muted font-weight-medium">
                (5 Reviews & Ratings)
              </span>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Mukesh Singh Negi
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">5.0</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
              <div className="pt-2">
                <p className="fs-small mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus quia consequuntur ipsum earum voluptatum, fugit
                  non culpa harum recusandae sequi?
                </p>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Kaushal Sharma
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">4</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
              <div className="pt-2">
                <p className="fs-small mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus quia consequuntur ipsum earum voluptatum, fugit
                  non culpa harum recusandae sequi?
                </p>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Aditya Singh Parmar
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">5</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
              <div className="pt-2">
                <p className="fs-small mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus quia consequuntur ipsum earum voluptatum, fugit
                  non culpa harum recusandae sequi?
                </p>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Akshay Kumar
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">5</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Salman Khan
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">4</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewRatingsModalHandler}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
      {/* view ratings popup  */}

      {/* view Pending Assistance popup  */}
      <Modal
        size="lg"
        show={viewPendingAssistanceModal}
        onHide={viewPendingAssistanceModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 ">
          <Modal.Title>
            <h4 className="text-primary">
              Pending Assistances
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="bg-white px-3 shadow pb-3 mb-5">
            <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
              <h4 className="text-primary">5 Pending Assistances</h4>
            </div>
            <div className="custom-table-wrapper border">
              <Table responsive hover className="custom-table" >
                <thead>
                  <tr>
                    <th className="text-nowrap">Order Id</th>
                    <th className="text-nowrap">Table Number</th>
                    <th className="text-nowrap">Waiter Assigned </th>
                    <th>Duration </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>454548</td>
                    <td>Table No. 2</td>
                    <td>Mukesh Kumar</td>
                    <td>2Min Ago</td>
                  </tr>
                  <tr>
                    <td>878878</td>
                    <td>Table No. 10</td>
                    <td>Ramesh Kumar</td>
                    <td>5Min Ago</td>
                  </tr>
                  <tr>
                    <td>458648</td>
                    <td>Table No. 5</td>
                    <td>Prashant Kumar</td>
                    <td>15Min Ago</td>
                  </tr>
                  <tr>
                    <td>454788</td>
                    <td>Table No. 6</td>
                    <td>Vikesh Kumar</td>
                    <td>10Min Ago</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewPendingAssistanceModalHandler}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
      {/* view Pending Assistance popup  */}

      {/* view Pending Orders popup  */}
      <Modal
        size="lg"
        show={viewPendingOrdersModal}
        onHide={viewPendingOrdersModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 ">
          <Modal.Title>
            <h4 className="text-primary">
              Pending Orders
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="bg-white px-3 shadow pb-3 mb-5">
            <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
              <h4 className="text-primary">5 Pending Orders</h4>
            </div>
            <div className="custom-table-wrapper border">
              <Table responsive hover className="custom-table" >
                <thead>
                  <tr>
                    <th className="text-nowrap">Order Id</th>
                    <th className="text-nowrap">Table Number</th>
                    <th className="text-nowrap">Waiter Assigned </th>
                    <th>Ordered (time) </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>454548</td>
                    <td>Table No. 2</td>
                    <td>Mukesh Kumar</td>
                    <td>2Min Ago</td>
                  </tr>
                  <tr>
                    <td>878878</td>
                    <td>Table No. 10</td>
                    <td>Ramesh Kumar</td>
                    <td>5Min Ago</td>
                  </tr>
                  <tr>
                    <td>458648</td>
                    <td>Table No. 5</td>
                    <td>Prashant Kumar</td>
                    <td>15Min Ago</td>
                  </tr>
                  <tr>
                    <td>454788</td>
                    <td>Table No. 6</td>
                    <td>Vikesh Kumar</td>
                    <td>10Min Ago</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewPendingOrdersModalHandler}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
      {/* view Pending Orders popup  */}


      {/* view Available  popup  */}
      <Modal
        size="lg"
        show={viewAvailableTablesModal}
        onHide={viewAvailableTablesModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 ">
          <Modal.Title>
            <h4 className="text-primary">
              Available Tables
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="bg-white px-3 shadow pb-3 mb-5">
            <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
              <h4 className="text-primary">5 Available Tables</h4>
            </div>
            <div className="p-3 border">
              <div className="row">
                <div className="col-4 mb-3">
                  <div className="rounded p-3 bg-light text-center text-primary">
                    <h3>Table No. 5</h3>
                    <h5>Couple Section</h5>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="rounded p-3 bg-light text-center text-primary">
                    <h3>Table No. 10</h3>
                    <h5>Family Section</h5>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="rounded p-3 bg-light text-center text-primary">
                    <h3>Table No. 5</h3>
                    <h5>Common Section</h5>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="rounded p-3 bg-light text-center text-primary">
                    <h3>Table No. 7</h3>
                    <h5>Couple Section</h5>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="rounded p-3 bg-light text-center text-primary">
                    <h3>Table No. 2</h3>
                    <h5>Couple Section</h5>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <div className="rounded p-3 bg-light text-center text-primary">
                    <h3>Table No. 8</h3>
                    <h5>Couple Section</h5>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewAvailableTablesModalHandler}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
      {/* view Available  popup popup  */}
    </div>
  );
}
