export const categoryConstants = {
  //login
  CREATE_CATEGORY_DETAILS_USER_ACTION_REQUEST: "CREATE_CATEGORY_DETAILS_USER_ACTION_REQUEST",
  CREATE_CATEGORY_DETAILS_USER_ACTION_SUCCESS: "CREATE_CATEGORY_DETAILS_USER_ACTION_SUCCESS",
  CREATE_CATEGORY_DETAILS_USER_ACTION_FAILURE: "CREATE_CATEGORY_DETAILS_USER_ACTION_FAILURE",

  //get category
  GET_CATEGORY_DETAILS_USER_ACTION_REQUEST: "GET_CATEGORY_DETAILS_USER_ACTION_REQUEST",
  GET_CATEGORY_DETAILS_USER_ACTION_SUCCESS: "GET_CATEGORY_DETAILS_USER_ACTION_SUCCESS",
  GET_CATEGORY_DETAILS_USER_ACTION_FAILURE: "GET_CATEGORY_DETAILS_USER_ACTION_FAILURE",

  //ADD_CATEGORY
  UPDATE_CATEGORY_DETAILS_USER_ACTION_REQUEST: "UPDATE_CATEGORY_DETAILS_USER_ACTION_REQUEST",
  UPDATE_CATEGORY_DETAILS_USER_ACTION_SUCCESS: "UPDATE_CATEGORY_DETAILS_USER_ACTION_SUCCESS",
  UPDATE_CATEGORY_DETAILS_USER_ACTION_FAILURE: "UPDATE_CATEGORY_DETAILS_USER_ACTION_FAILURE",

  //login
  DELETE_CATEGORY_DETAILS_USER_ACTION_REQUEST: "DELETE_CATEGORY_DETAILS_USER_ACTION_REQUEST",
  DELETE_CATEGORY_DETAILS_USER_ACTION_SUCCESS: "DELETE_CATEGORY_DETAILS_USER_ACTION_SUCCESS",
  DELETE_CATEGORY_DETAILS_USER_ACTION_FAILURE: "DELETE_CATEGORY_DETAILS_USER_ACTION_FAILURE",
};
