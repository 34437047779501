import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Table, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faStopwatch,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
export default function SocialMediaManagement() {
  const [viewSocialMedia, SetViewSocialMedia] = useState(false);
  const navigate = useNavigate();
  const viewSocialMediaHandler = () => {
    SetViewSocialMedia(!viewSocialMedia);
  };
  return (
    <div className="admin-dashboard-section">
      <Sidebar />
      <div className="dashboard-main-content menu-managment-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="bg-white px-3 shadow pb-3 mb-5">
            <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
              <h4 className="text-primary">All Orders</h4>
              <button className="ms-auto btn btn-primary"  onClick={viewSocialMediaHandler}>+ Add Social link</button>
            </div>
            <div className="custom-table-wrapper border">
              <Table responsive hover className="custom-table" >
                <thead>
                  <tr>
                    <th className="text-nowrap">Sl No.</th>
                    <th className="text-nowrap">Platform Name</th>
                    <th className="text-nowrap">Account Name</th>
                    <th className="text-nowrap">Account Link</th>
                    <th className="text-nowrap">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="me-3">
                          <img src="./assets/images/youtube-logo.png" alt="Youtube" />
                        </span>
                        Youtube
                      </div>
                    </td>
                    <td>TopFoods</td>
                    <td>
                      <a href="https://www.youtube.com/"> https://www.youtube.com/</a>
                    </td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewSocialMediaHandler}
                      >Edit</button></td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="me-3">
                          <img src="./assets/images/facebook-logo.png" alt="facebook" />
                        </span>
                        Facebook
                      </div>
                    </td>
                    <td>TopFoods</td>
                    <td>
                      <a href="https://www.facebook.com/"> https://www.facebook.com/</a>
                    </td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewSocialMediaHandler}
                      >Edit</button></td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="me-3">
                          <img src="./assets/images/instagram-logo.png" alt="instagram" />
                        </span>
                        Instagram
                      </div>
                    </td>
                    <td>TopFoods</td>
                    <td>
                      <a href="https://www.instagram.com/"> https://www.instagram.com/</a>
                    </td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewSocialMediaHandler}
                      >Edit</button></td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="me-3">
                          <img src="./assets/images/twitter-logo.png" alt="twitter" />
                        </span>
                        Twitter
                      </div>
                    </td>
                    <td>TopFoods</td>
                    <td>
                      <a href="https://www.twitter.com/"> https://www.twitter.com/</a>
                    </td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewSocialMediaHandler}
                      >Edit</button></td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
      {/* Order Modal */}
      <Modal
        size="md"
        show={viewSocialMedia}
        onHide={viewSocialMediaHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title>
            <h4 className="text-primary">Social Media Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="border p-3 rounded">
            <div className="row">
              <div className="col-6">
                <div className="form-group mb-4">
                  <label htmlFor="" className="mb-3 font-weight-semi">Social Media Name</label>
                  <select name="" id="" className="form-select ">
                    <option value="">Select</option>
                    <option value="">Youtube</option>
                    <option value="">Facebook</option>
                    <option value="">Instagram</option>
                    <option value="">Twitter</option>
                  </select>
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-4">
                  <label htmlFor="" className="mb-3 font-weight-semi">Social Media Name</label>
                  <input type="text" placeholder="Social Media Name" className="form-control" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-4">
                  <label htmlFor="" className="mb-3 font-weight-semi">Account Name</label>
                  <input type="text" placeholder="Account Name" className="form-control" />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group mb-4">
                  <label htmlFor="" className="mb-3 font-weight-semi">Social Media Link</label>
                  <input type="text" placeholder="Social Media Link" className="form-control" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-secondary" onClick={() => { navigate("/add-offer") }}>
            Save
          </button>
          <button className="btn btn-primary" onClick={viewSocialMediaHandler}>
            Delete
          </button>
          <button className="btn btn-primary" onClick={viewSocialMediaHandler}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  Order  Modal */}
    </div>
  );
}