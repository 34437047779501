import axios from "axios";
import Config from "./config";
const baseURL = Config.apiUrl;
// axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
// axios.defaults.xsrfCookieName = "csrftoken";
const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(
  function (config) {
    const user = JSON.parse(localStorage.getItem("menulao-user"));
    const access_token = user && user.access_token ? user.access_token : "";
    config.headers.Authorization =
      access_token && access_token.length ? `Bearer ${access_token}` : null;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      alert("You are not authorized");
    }
    return response;
  },
  (error) => {
    console.log("eabkfkaebf", error);
    if (
      error.response &&
      // error.response.data &&
      // error.response.data.status === 401

      error.response.status === 401
    ) {
      localStorage.removeItem("menulao-user");
      window.location.reload();
      return Promise.reject(error.response && error.response.data);
    }
    return Promise.reject(error.response.data);
  }
);
export default axiosInstance;
