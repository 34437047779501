import React from "react";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import { Form } from "react-bootstrap";

export const AddTable = () => {
  return (
    <>
      <div className="admin-dashboard-section pg-add-food-item pb-5">
        <Sidebar activeMenuItem="table-management" />
        <div className="dashboard-main-content add-table-wrapper">
          <Header pageTitle="Add Food Item" />
          <div className="main-content">
            <div className="container">
              <Form>
                <div className="row justify-content-center">
                  <div className="col-lg-10 mb-4">
                    <h3 className="text-primary">Add New Table In The Restaurant</h3>
                  </div>

                  <div className="col-lg-10 ">
                    <div className="bg-white shadow rounded-lg p-3 mb-4">
                      {/* <div className="page-top my-4 text-center">
                        <h3 className="text-primary mb-0">Add New Table </h3>
                        <div className="top-img">
                          <img src={"/assets/images/restaurant.png"} alt="" />
                        </div>
                      </div> */}
                      <div className="border-bottom pb-3 mb-4">
                        <h5 className="text-primary">Add New Table Information</h5>
                      </div>

                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group mb-3 img-upload-wrapper">
                            <label
                              htmlFor=""
                              className="font-weight-semi mb-2 d-block"
                            >
                              Upload Table Image
                            </label>
                            <label
                              htmlFor="RestaurantLogo"
                              className="img-upload bg-grey p-3"
                            >
                              <input
                                type="file"
                                id="RestaurantLogo"
                                className="d-none"
                              />
                              <img
                                src="./assets/images/photo-placeholder.png"
                                alt="placeholder"
                              />
                            </label>
                            <p className="">png, jpg files only...</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3 img-upload-wrapper">
                            <label
                              htmlFor=""
                              className="font-weight-semi mb-2 d-block"
                            >
                              Upload Table Section Video
                            </label>
                            <label
                              htmlFor="RestaurantLogo"
                              className="img-upload bg-grey p-3"
                            >
                              <input
                                type="file"
                                id="RestaurantLogo"
                                className="d-none"
                              />
                              <img
                                src="./assets/images/video-camera.png"
                                alt="placeholder"
                              />
                            </label>
                            <p className="">mp4, mpeg files only...</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group>
                            <Form.Label className="mb-2 font-weight-medium pl-2">
                              Table Id (Table No.)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="1"
                              disabled
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2 font-weight-medium pl-2">
                              Capacity
                            </Form.Label>
                            <Form.Control as="select" className="form-select">
                              <option value="">Select</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                              <option value="11">11</option>
                              <option value="12">12</option>
                              <option value="13">13</option>
                              <option value="14">14</option>
                              <option value="15">15</option>
                              <option value="16">16</option>
                              <option value="17">17</option>
                              <option value="18">18</option>
                              <option value="19">19</option>
                              <option value="20">20</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2 font-weight-medium pl-2">
                              Floor
                            </Form.Label>
                            <Form.Control as="select" className="form-select">
                              <option value="">Select</option>
                              <option value="">Ground Floor</option>
                              <option value="">1st Floor</option>
                              <option value="">2nd Floor</option>
                              <option value="">3rd Floor</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label className="mb-2 font-weight-medium pl-2">
                              Special Section
                            </Form.Label>
                            <Form.Control as="select" className="form-select">
                              <option value="">Select</option>
                              <option value="">Couple Section</option>
                              <option value="">Family Section</option>
                              <option value="">Kids Section</option>
                              <option value="">Birthday Special Section</option>
                            </Form.Control>
                          </Form.Group>
                        </div>
                        <div className="col-lg-12 action d-flex align-items-center justify-content-end pt-3 border-top">
                          <button className="btn btn-secondary me-3">
                            Submit{" "}
                          </button>
                          <button className="btn btn-danger me-3">Delete</button>
                          <button className="btn btn-primary">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
