import React, { useState } from "react";
import { Modal, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faEye, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { QRCodeSVG } from "qrcode.react";
import useQRCodeManagement from "./useQRCodeManagement";
import { useNavigate } from "react-router-dom";
export default function QRCodeManagement() {
  const navigate = useNavigate();
  const {
    tableDetails,
    deletePopupModal,
    viewTableModal,
    handleDeletePopupModalShow,
    handleViewTableModalShow,
    getQrCodeValue,
  } = useQRCodeManagement();

  return (
    <div className="admin-dashboard-section pg-category-list">
      <Sidebar activeMenuItem="qr-code-generator" />
      <div className="dashboard-main-content tables-wapper">
        <Header />
        <div className="bg-white px-3 shadow pb-3 mb-5">
          <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
            <h4 className="text-primary">QR Management</h4>
            <button className="ms-auto btn btn-primary" onClick={() => { navigate("/generate-qr") }}>+ Create New</button>
          </div>
          <div className="custom-table-wrapper border">
            <Table responsive hover className="custom-table" >
              <thead>
                <tr>
                  <th className="text-nowrap">Sl No.</th>
                  <th className="text-nowrap">Table Number</th>
                  <th className="text-nowrap">Table Location</th>
                  <th className="text-nowrap">Section</th>
                  <th className="text-nowrap">Capacity</th>
                  <th className="text-nowrap">View</th>
                  <th className="text-nowrap">Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>Table No.1</td>
                  <td>First Floor</td>
                  <td>Couples </td>
                  <td>10 Seats</td>
                  <td>
                    <span
                      className="btn-view cursor-pointer text-success font-weight-semi"
                      onClick={() => handleViewTableModalShow(1)}
                    >
                      <FontAwesomeIcon icon={faEye} className="me-2" />
                      View
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn-delete cursor-pointer text-danger font-weight-semi"
                      onClick={handleDeletePopupModalShow}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Delete
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Table No.2</td>
                  <td>First Floor</td>
                  <td>Family </td>
                  <td>10 Seats</td>
                  <td>
                    <span
                      className="btn-view cursor-pointer text-success font-weight-semi"
                      onClick={() => handleViewTableModalShow(2)}
                    >
                      <FontAwesomeIcon icon={faEye} className="me-2" />
                      View
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn-delete cursor-pointer text-danger font-weight-semi"
                      onClick={handleDeletePopupModalShow}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Delete
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>Table No.3</td>
                  <td>First Floor</td>
                  <td>Couples </td>
                  <td>10 Seats</td>
                  <td>
                    <span
                      className="btn-view cursor-pointer text-success font-weight-semi"
                      onClick={() => handleViewTableModalShow(3)}
                    >
                      <FontAwesomeIcon icon={faEye} className="me-2" />
                      View
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn-delete cursor-pointer text-danger font-weight-semi"
                      onClick={handleDeletePopupModalShow}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Delete
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>Table No.4</td>
                  <td>First Floor</td>
                  <td>Family </td>
                  <td>10 Seats</td>
                  <td>
                    <span
                      className="btn-view cursor-pointer text-success font-weight-semi"
                      onClick={() => handleViewTableModalShow(4)}
                    >
                      <FontAwesomeIcon icon={faEye} className="me-2" />
                      View
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn-delete cursor-pointer text-danger font-weight-semi"
                      onClick={handleDeletePopupModalShow}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Delete
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>Table No.5</td>
                  <td>First Floor</td>
                  <td>Couples </td>
                  <td>10 Seats</td>
                  <td>
                    <span
                      className="btn-view cursor-pointer text-success font-weight-semi"
                      onClick={() => handleViewTableModalShow(5)}
                    >
                      <FontAwesomeIcon icon={faEye} className="me-2" />
                      View
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn-delete cursor-pointer text-danger font-weight-semi"
                      onClick={handleDeletePopupModalShow}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Delete
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>Table No.6</td>
                  <td>First Floor</td>
                  <td>Family </td>
                  <td>10 Seats</td>
                  <td>
                    <span
                      className="btn-view cursor-pointer text-success font-weight-semi"
                      onClick={() => handleViewTableModalShow(6)}
                    >
                      <FontAwesomeIcon icon={faEye} className="me-2" />
                      View
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn-delete cursor-pointer text-danger font-weight-semi"
                      onClick={handleDeletePopupModalShow}
                    >
                      <FontAwesomeIcon icon={faTrash} className="me-2" />
                      Delete
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* delete table popup */}
      <Modal
        size="sm"
        show={deletePopupModal}
        onHide={handleDeletePopupModalShow}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-secondary"> Delete table</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Do you really want to delete this table?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={handleDeletePopupModalShow}
          >
            Cancel
          </button>
          <button className="btn btn-danger">Delete</button>
        </Modal.Footer>
      </Modal>
      {/* delete table popup ends */}

      {/* view table details */}
      <Modal
        size="lg"
        show={viewTableModal}
        onHide={() => handleViewTableModalShow()}
        centered
        className="view-table-modal"
      >
        <Modal.Header closeButton >
          <Modal.Title>
            <h4>QR Information</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6  d-flex align-items-center justify-content-center text-white staff-brief-wrapper ">
                <div className="staff-brief p-1 text-center">
                  <div className="staff-img ">
                    {tableDetails && <QRCodeSVG value={getQrCodeValue()} fgColor="#252939"
                      bgColor="#ffffff"
                      size="200"
                      level="M"
                    />}
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h3 className="text-primary mb-3">Table Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Table Number
                    </p>
                    <p className="fs-small mb-1">1</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p className="font-weight-semi text-primary mb-1">
                      Capacity(Seats)
                    </p>
                    <p className="fs-small mb-1">8 People</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p className="font-weight-semi text-primary mb-1">
                      Location
                    </p>
                    <p className="fs-small mb-1">1st Floor</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Special Section
                    </p>
                    <p className="fs-small mb-1">Couples Section</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-success">Edit</button>
          <button
            className="btn btn-danger"
            onClick={handleDeletePopupModalShow}
          >
            Delete
          </button>
          <button
            className="btn btn-primary"
            onClick={() => navigate("/download-qr")}
          >
            Download
          </button>
        </Modal.Footer>
      </Modal>
      {/* view table details */}
    </div>
  );
}
