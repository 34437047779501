import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Table, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faStopwatch,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
export default function OrdersManagement() {
  const [viewOrderModal, SetViewOrderModal] = useState(false);
  const [viewInstructionsModal, SetViewInstructionsModal] = useState(false);
  const [viewOrderStatusModal, SetViewOrderStatusModal] = useState(false);
  const [bookingRequestModal, SetbookingRequestModal] = useState(false);
  const [newItemAdditionModal, SetNewItemAdditionModal] = useState(false);

  const navigate = useNavigate();

  const viewOrderModalHandler = () => {
    SetViewOrderModal(!viewOrderModal);
  };
  const viewInstructionsModalHandler = () => {
    SetViewInstructionsModal(!viewInstructionsModal);
  };
  const viewOrderStatusModalHandler = () => {
    SetViewOrderStatusModal(!viewOrderStatusModal);
  };
  const bookingRequestModalHandler = () => {
    SetbookingRequestModal(!bookingRequestModal);
  };

  return (
    <div className="admin-dashboard-section">
      <Sidebar />
      <div className="dashboard-main-content menu-managment-main">
        <Header pageTitle="Dashboard" />
        <div className="container">

          <Tabs
            defaultActiveKey="All"
            className="custom-tabs"
          >
            <Tab eventKey="All" title="All">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom">
                  <h4 className="text-primary">All Orders</h4>
                </div>

                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Table Number</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-danger text-white d-inline-block py-1 px-2  font-small">New Order</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm"
                          onClick={bookingRequestModalHandler}
                        >View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-warning text-white d-inline-block py-1 px-2  font-small"

                          >Processing</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm"
                          onClick={viewOrderStatusModalHandler}
                        >View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-success text-white d-inline-block py-1 px-2  font-small">Completed</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm"
                          onClick={viewOrderStatusModalHandler}
                        >View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-danger text-white d-inline-block py-1 px-2  font-small">New Order</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="RequestedOrders" title="Requested Orders">
              <div className="bg-white shadow px-3 pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom">
                  <h4 className="text-primary">Requested Orders</h4>
                </div>

                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Table Number</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-danger text-white d-inline-block py-1 px-2  font-small"
                            onClick={bookingRequestModalHandler}
                          >New Order</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-danger text-white d-inline-block py-1 px-2 font-small">New Order</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-danger text-white d-inline-block py-1 px-2  font-small">New Order</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-danger text-white d-inline-block py-1 px-2  font-small">New Order</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="CurrentOrders" title="Open Orders">
              <div className="bg-white shadow px-3 pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom">
                  <h4 className="text-primary">Current Orders</h4>
                </div>

                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Table Number</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-warning text-white d-inline-block py-1 px-2  font-small">Processing</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-warning text-white d-inline-block py-1 px-2 font-small">Processing</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-warning text-white d-inline-block py-1 px-2  font-small">Processing</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-warning text-white d-inline-block py-1 px-2  font-small">Processing</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>

            </Tab>
            <Tab eventKey="CompletedOrders" title="Completed Orders" >
              <div className="bg-white shadow px-3 pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom">
                  <h4 className="text-primary">Completed Orders</h4>
                </div>

                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th>Order Id</th>
                        <th>Date</th>
                        <th>Customer Name</th>
                        <th>Table Number</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-success text-white d-inline-block py-1 px-2  font-small">Completed</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-success text-white d-inline-block py-1 px-2 font-small">Completed</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-success text-white d-inline-block py-1 px-2  font-small">Completed</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                      <tr>
                        <td>235645</td>
                        <td>18/11/2022</td>
                        <td>Mukesh Singh</td>
                        <td>Table No. 3</td>
                        <td>
                          <span className="bg-success text-white d-inline-block py-1 px-2  font-small">Completed</span>
                        </td>
                        <td><span className="btn btn-primary btn-sm">View</span></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
          </Tabs>

        </div>
      </div>


      {/*Booking Action Modal */}
      <Modal
        size="lg"
        show={bookingRequestModal}
        onHide={bookingRequestModalHandler}
        centered
        id="viewOrderStatusModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Order Request</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <h5 className="mb-4 d-flex align-items-center text-primary justify-content-between">
            Table Number 1 has placed booking request{" "}
            <button
              className="btn btn-secondary btn-sm ms-2"
              onClick={viewOrderModalHandler}
            >
              View Order
            </button>
          </h5>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Table Number
                  </Form.Label>
                  <Form.Control type="text" placeholder="1" disabled />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Take Action
                  </Form.Label>
                  <Form.Control as="select">
                    <option value="">Select</option>
                    <option value="Accept">Accept</option>
                    <option value="Pizzas">Reject</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Assign Waiter
                  </Form.Label>
                  <Form.Control as="select">
                    <option value="">Select</option>
                    <option value="">Mukesh</option>
                    <option value="">Kaushal</option>
                    <option value="">Aditya</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Assign Cook
                  </Form.Label>
                  <Form.Control as="select">
                    <option value="">Select</option>
                    <option value="">Mukesh</option>
                    <option value="">Kaushal</option>
                    <option value="">Aditya</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Estimate Time (In Minutes)
                  </Form.Label>
                  <Form.Control
                    type="time"
                    placeholder="Esmitate Time (in Minutes)"
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-secondary"
            onClick={bookingRequestModalHandler}
          >
            Accept
          </button>
          <button
            className="btn btn-primary"
            onClick={bookingRequestModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* Booking Action Modal */}


      {/* Order Modal */}
      <Modal
        size="lg"
        show={viewOrderModal}
        onHide={viewOrderModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title>
            <h4 className="text-primary">View Order (Table No. 1)</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <ul className="p-3">
            <li className="border-bottom py-2 border-thick text-primary border-bottom">
              <div className="row mb-0 font-weight-bold ">
                <div className="col-4">Item</div>
                <div className="col-2 text-center">Quantity</div>
                <div className="col-3 text-center">Price</div>
                <div className="col-3 text-right">Total Price</div>
              </div>
            </li>
            <li className="border-bottom py-2">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Burger</span>
                  <div className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Red Sauce</span>{" "}
                  </div>
                  <span
                    className="bg-secondary py-1 px-2 d-inline-block mt-2 text-white fs-small cursor-pointer"
                    onClick={viewInstructionsModalHandler}
                  >
                    see instructions
                  </span>
                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="mb-1  py-2">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Pizza</span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Red Sauce</span>{" "}
                  </span>
                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="border-bottom py-2">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">
                    Chicken Curry
                  </span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Extra Sauce</span>{" "}
                  </span>
                </div>
                <div className="col-2 text-center">X 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="border-bottom py-2">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Burger</span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Extra Cheese Slice</span>{" "}
                  </span>
                </div>
                <div className="col-2 text-center">X 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
          </ul>
          <ul className="bg-light p-3">
            <li className="font-weight-semi d-flex align-items-center justify-content-between">
              <span>Sub Total</span>
              <span className="ms-1">₹400</span>
            </li>

            <li className="fs-small  font-weight-medium text-success d-flex align-items-center justify-content-between">
              <span>Discount</span>
              <span className="ms-1">₹100</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>SGST@2.5%</span>
              <span className="ms-1">₹50</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>CGST@2.5%</span>
              <span className="ms-1">₹50</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>Service Tax@5%</span>
              <span className="ms-1">₹100</span>
            </li>
            <li className="font-weight-bold text-secondary d-flex align-items-center justify-content-between">
              <span>Sub Total</span>
              <span className="ms-1">₹400</span>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-primary" onClick={viewOrderModalHandler}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  Order  Modal */}


      {/* View Instructions Modal */}
      <Modal
        size="sm"
        show={viewInstructionsModal}
        onHide={viewInstructionsModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title>
            <h4 className="text-secondary">Instructions</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores,
            rem saepe magnam ut deleniti nihil. Quisquam quod voluptas deleniti
            nobis!
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewInstructionsModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  View Instructions  Modal */}


      {/* View Order Status Modal */}
      <Modal
        size="lg"
        show={viewOrderStatusModal}
        onHide={viewOrderStatusModalHandler}
        centered
        id="viewOrderStatusModal"
      >
        <Modal.Header closeButton >
          <Modal.Title>
            <h4>Order Status</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <h4 className="d-flex align-items-center justify-content-between mt-3 mb-4">
            <span>Table No.1</span>
            <span className="fs-small">
              <FontAwesomeIcon icon={faStopwatch} className="me-2" />
              10:00
            </span>
          </h4>

          <article class="card">
            <header class="card-header"> My Orders / Tracking </header>
            <div class="card-body">
              <h6>Order ID: OD45345345435</h6>
              <div class="track">
                <div class="step active">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />{" "}
                  </span>
                  <span class="text">Order confirmed</span>{" "}
                </div>
                <div class="step active">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />{" "}
                  </span>
                  <span class="text"> Food Preparing</span>{" "}
                </div>
                <div class="step">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span class="text">Ready to Serve</span>{" "}
                </div>
                <div class="step">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />{" "}
                  </span>
                  <span class="text">Delivered</span>{" "}
                </div>
              </div>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewOrderStatusModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* View Order Status Modal */}s

    </div>
  );
}
