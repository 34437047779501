import { categoryListsConstants } from "../_constants";

const initialState = {};

//login reducer
export const get_category_lists_user_reducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case categoryListsConstants.GET_CATEGORY_LISTS_USER_ACTION_REQUEST:
      return {
        type: "alert-success",
        message: action.payload,
      };
    case categoryListsConstants.GET_CATEGORY_LISTS_USER_ACTION_SUCCESS:
      return {
        loggedIn: true,
        categoryListData: action.payload,
      };
    case categoryListsConstants.GET_CATEGORY_LISTS_USER_ACTION_FAILURE:
      return {
        type: "alert-failure",
        message: action.payload,
      };
    default:
      return state;
  }
};
