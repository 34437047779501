import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Table, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faStopwatch,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
export default function InventoryManagement() {
  const [viewOffer, SetViewOffer] = useState(false);

  const navigate = useNavigate();

  const viewOfferHandler = () => {
    SetViewOffer(!viewOffer);
  };
  return (
    <div className="admin-dashboard-section">
      <Sidebar />
      <div className="dashboard-main-content menu-managment-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="bg-white px-3 shadow pb-3 mb-5">
            <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
              <h4 className="text-primary">All Inventory</h4>
              <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-inventory") }}>+ Add New Invetory</button>
            </div>
            <div className="custom-table-wrapper border">
              <Table responsive hover className="custom-table" >
                <thead>
                  <tr>
                    <th className="text-nowrap">Inventory Id</th>
                    <th className="text-nowrap">Inventory Name</th>
                    <th className="text-nowrap">Inventory Description </th>
                    <th>View Inventory</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>4466</td>
                    <td>
                      <span className="text-underline cursor-pointer" onClick={() => { navigate("/add-inventory") }}>Frozen Foods</span>
                    </td>
                    <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, maxime?</td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={() => { navigate("/view-inventory") }}
                      >View Inventory</button>
                    </td>
                  </tr>
                  <tr>
                    <td>4466</td>
                    <td> <span className="text-underline cursor-pointer" onClick={() => { navigate("/add-inventory") }}>Frozen Foods</span></td>
                    <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, maxime?</td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={() => { navigate("/view-inventory") }}
                      >View Inventory</button>
                    </td>
                  </tr>
                  <tr>
                    <td>4466</td>
                    <td> <span className="text-underline cursor-pointer" onClick={() => { navigate("/add-inventory") }}>Frozen Foods</span></td>
                    <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores, maxime?</td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={() => { navigate("/view-inventory") }}
                      >View Inventory</button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

        </div>
      </div>
   </div>
  );
}
