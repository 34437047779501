import React from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
export default function Support() {
  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="support" />
      <div className="dashboard-main-content settings-main pb-4">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <img src={"./assets/images/support.svg"} alt="support" />
            </div>
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="p-4 shadow border bg-white rounded">
                <h3 className="text-primary">Write us your query</h3>
                <p>We'l get back to you soon</p>
                <form className="mt-5">
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi">
                      Phone Number
                    </label>
                    <input type="text" className="form-control" placeholder="" />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi">
                      Message
                    </label>
                    <textarea rows="5" className="form-control"></textarea>
                    
                  </div>
                  <div className="action mt-3 p-2 d-flex align-items-center justify-content-end">
                    <button className="btn btn-primary">Submit </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
