import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Form, Modal, Table, Tabs, Tab } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faStopwatch,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
export default function OffersManagement() {
  const [viewOffer, SetViewOffer] = useState(false);

  const navigate = useNavigate();

  const viewOfferHandler = () => {
    SetViewOffer(!viewOffer);
  };
  return (
    <div className="admin-dashboard-section">
      <Sidebar />
      <div className="dashboard-main-content menu-managment-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <Tabs
            defaultActiveKey="All"
            className="custom-tabs"
          >
            <Tab eventKey="All" title="All">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
                  <h4 className="text-primary">All Orders</h4>
                  <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-offer") }}>+ Add New Offer</button>
                </div>
                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th className="text-nowrap">Id</th>
                        <th className="text-nowrap">Offer Code</th>
                        <th className="text-nowrap">Type</th>
                        <th className="text-nowrap">Status</th>
                        <th className="text-nowrap">Description</th>
                        <th className="text-nowrap">Start Date</th>
                        <th className="text-nowrap">End Date</th>
                        <th className="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>256641</td>
                        <td>FIRST50OFF
                          <span className="d-block font-small">
                            (50% Discount/Max Rs.500)
                          </span>
                        </td>
                        <td>Restricted Discount </td>
                        <td>
                          <span className="bg-successs py-1 px-2">Active</span>
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, quisquam</td>
                        <td>22/11/2022</td>
                        <td>25/11/2022</td>
                        <td><button className="btn btn-primary btn-sm"
                          onClick={viewOfferHandler}
                        >View</button></td>
                      </tr>
                      <tr>
                        <td>256641</td>
                        <td>COMPLETE50OFF
                          <span className="d-block font-small">
                            (Flat 50% Discount)
                          </span>
                        </td>
                        <td>Flat Discount</td>
                        <td>
                          <span className="bg-successs py-1 px-2">Active</span>
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, quisquam</td>
                        <td>22/11/2022</td>
                        <td>25/11/2022</td>
                        <td><button className="btn btn-primary btn-sm"
                          onClick={viewOfferHandler}
                        >View</button></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Active" title="Active Offers">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
                  <h4 className="text-primary">All Orders</h4>
                  <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-offer") }}>+ Add New Offer</button>
                </div>
                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th className="text-nowrap">Id</th>
                        <th className="text-nowrap">Offer Code</th>
                        <th className="text-nowrap">Type</th>
                        <th className="text-nowrap">Status</th>
                        <th className="text-nowrap">Description</th>
                        <th className="text-nowrap">Start Date</th>
                        <th className="text-nowrap">End Date</th>
                        <th className="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>256641</td>
                        <td>FIRST50OFF
                          <span className="d-block font-small">
                            (Max Rs.500)
                          </span>
                        </td>
                        <td>Restricted % Checkout Discount </td>
                        <td>
                          <span className="bg-successs py-1 px-2">Active</span>
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, quisquam</td>
                        <td>22/11/2022</td>
                        <td>25/11/2022</td>
                        <td><button className="btn btn-primary btn-sm"
                          onClick={viewOfferHandler}
                        >View</button></td>
                      </tr>
                      <tr>
                        <td>256641</td>
                        <td>COMPLETE50OFF</td>
                        <td>Fixed % Checkouts Discount</td>
                        <td>
                          <span className="bg-successs py-1 px-2">Active</span>
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, quisquam</td>
                        <td>22/11/2022</td>
                        <td>25/11/2022</td>
                        <td><button className="btn btn-primary btn-sm"
                          onClick={viewOfferHandler}
                        >View</button></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Completed" title="Completed Offers">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
                  <h4 className="text-primary">All Orders</h4>
                  <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-offer") }}>+ Add New Offer</button>
                </div>
                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th className="text-nowrap">Id</th>
                        <th className="text-nowrap">Offer Code</th>
                        <th className="text-nowrap">Type</th>
                        <th className="text-nowrap">Status</th>
                        <th className="text-nowrap">Description</th>
                        <th className="text-nowrap">Start Date</th>
                        <th className="text-nowrap">End Date</th>
                        <th className="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>256641</td>
                        <td>FIRST50OFF
                          <span className="d-block font-small">
                            (Max Rs.500)
                          </span>
                        </td>
                        <td>Restricted % Checkout Discount </td>
                        <td>
                          <span className="bg-successs py-1 px-2">Active</span>
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, quisquam</td>
                        <td>22/11/2022</td>
                        <td>25/11/2022</td>
                        <td><button className="btn btn-primary btn-sm"
                          onClick={viewOfferHandler}
                        >View</button></td>
                      </tr>
                      <tr>
                        <td>256641</td>
                        <td>COMPLETE50OFF</td>
                        <td>Fixed % Checkouts Discount</td>
                        <td>
                          <span className="bg-successs py-1 px-2">Active</span>
                        </td>
                        <td>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, quisquam</td>
                        <td>22/11/2022</td>
                        <td>25/11/2022</td>
                        <td><button className="btn btn-primary btn-sm"
                          onClick={viewOfferHandler}
                        >View</button></td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>

          </Tabs>

        </div>
      </div>



      {/* Order Modal */}
      <Modal
        size="md"
        show={viewOffer}
        onHide={viewOfferHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title>
            <h4 className="text-primary">Offer Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="border p-3 bg-light rounded">
            <div className="row">
              <div className="col-6">
                <h6 className="text-primary">Offer Id</h6>
                <p className="font-weight-medium">4464644</p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Offer Code</h6>
                <p className="font-weight-medium">FIRST50OFF
                  <span className="ms-2 font-small">
                    (Max Rs.500)
                  </span>
                </p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Offer Type</h6>
                <p className="font-weight-medium">Restricted Discount</p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Offer Status</h6>
                <p className="font-weight-medium">
                  <span className="text-success font-weight-bold">Active</span>
                </p>
              </div>
              <div className="col-12">
                <h6 className="text-primary">Offer Description</h6>
                <p className="font-weight-medium">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, quisquam</p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Start Date</h6>
                <p className="font-weight-medium">22/11/2022</p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">End Date</h6>
                <p className="font-weight-medium">25/11/2022</p>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-secondary" onClick={() => { navigate("/add-offer") }}>
            Edit
          </button>
          <button className="btn btn-primary" onClick={viewOfferHandler}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  Order  Modal */}

    </div>
  );
}
