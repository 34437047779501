import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signin_user_action } from "../_redux";
import loginSchema from '../../../_helpers/schemas/login.json'
import { formValidator } from "../../../_helpers/validators/formValidator";
import { errorString } from "../../../_helpers/schemas/errors";

function useSignin() {
  const [formData, setFormData] = useState({});
  const [formError, setFormError] = useState({});
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.signin_user_reducer);

  useEffect(() => {
    if (user) {
      window.location.reload()
    }
    
  }, [user]);

  const onFormInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = formData;
    let error = formError;
    error[name] = null;
    data[name] = value;
    setFormData(data);
    setFormError(error);
  };

  const onSigninClick = (e) => {
    e.preventDefault();
    const [valid, error] = formValidator(formData, loginSchema);
    let errors = {};

    if (valid) {
      let inputsCheck =
        formData.username.length < 5 || formData.password.length < 7;
      if (inputsCheck) {
        errors["username"] = formData.username.length < 7
          ? errorString?.username
          : null;
        errors["password"] =
          formData.password.length < 7 ? errorString?.passwordLength : null;
        setFormError(errors);
      } else {
        dispatch(signin_user_action(formData));
      }
    } else {
      setFormError(error);
    }
  };

  return {
    formData,
    formError,

    onSigninClick,
    onFormInputChange,
  };
}

export default useSignin;
