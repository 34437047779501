import { categoryConstants } from "../_constants";


const initialState ={};

//login reducer
export const get_category_details_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case categoryConstants.CREATE_CATEGORY_USER_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case categoryConstants.CREATE_CATEGORY_USER_ACTION_SUCCESS:
        case categoryConstants.GET_CATEGORY_DETAILS_USER_ACTION_SUCCESS:
        case categoryConstants.UPDATE_CATEGORY_DETAILS_USER_ACTION_SUCCESS:
            return {
                loggedIn: true,
                categoryDetailsData: action.payload
            }
        case categoryConstants.CREATE_CATEGORY_USER_ACTION_FAILURE:
            return {
                type: "alert-failure",
                errorCategory: action.payload
            }
        default:
            return state;
    }
};