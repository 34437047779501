import { combineReducers } from "redux";
import { signin_user_reducer } from "../pages/Auth/_redux";
import { get_category_details_user_reducer, get_category_lists_user_reducer } from "../pages/Category/_redux";

import { get_menu_item_details_user_reducer, get_menu_item_lists_user_reducer } from "../pages/MenuItem/_redux";

const rootReducer = combineReducers({
  //auth
  signin_user_reducer,

  //category
  get_category_details_user_reducer,
  get_category_lists_user_reducer,

  //menu item
  get_menu_item_details_user_reducer,
  get_menu_item_lists_user_reducer
});

export default rootReducer;
