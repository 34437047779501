import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsProgress,
  faBowlFood,
  faBurger,
  faClipboardList,
  faCog,
  faDiceD6,
  faEnvelope,
  faExchangeAlt,
  faFileLines,
  faGauge,
  faHotdog,
  faLayerGroup,
  faMoneyBill,
  faPaste,
  faPieChart,
  faPoll,
  faQrcode,
  faReceipt,
  faSignOutAlt,
  faSquarePollVertical,
  faTable,
  faTags,
  faUserCog,
  faUserPlus,
  faUsers,
  faUsersCog,
  faUtensils,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { fab, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
export default function Sidebar(props) {
  const activeTab = props?.activeMenuItem;

  const navigate = useNavigate();
  return (
    <div className="sidebar bg-primary">
      <div className="resturant-logo d-flex align-items-center ">
        <div className="logo me-2 d-flex align-items-center">
          <img src={"/assets/images/hot-pot.png"} alt="resturant logo" />
        </div>
        <h3 className="mb-0">FunDine</h3>
      </div>
      <div className="side-menu">
        <ul>
          <li>
            <button onClick={() => navigate("/dashboard")} className={activeTab === "dashboard" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faGauge} />
              </span>
              Dashboard
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/menu-management")} className={activeTab === "menu-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faUtensils} />

              </span>
              Menu Management
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/table-management")} className={activeTab === "table-management" ? "active" : ""}>
              <span className="icon me-2" >
                <FontAwesomeIcon icon={faDiceD6} />
              </span>
              Table Management
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/staff-management")} className={activeTab === "staff-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faUserCog} />
              </span>
              Staff Management
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/orders-management")} className={activeTab === "orders-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faReceipt} />
              </span>
              Orders Management
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/inventory-management")} className={activeTab === "inventory-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faWarehouse} />
              </span>
              Inventory Management
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/offers-management")} className={activeTab === "offers-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faTags} />
              </span>
              Offer Management
            </button>
          </li>

          <li>
            <button onClick={() => navigate("/qr-code-generator")} className={activeTab === "qr-code-generator" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faQrcode} />
              </span>
              QR Code Generator
            </button>
          </li>

          <li>
            <button onClick={() => navigate("/payments-management")} className={activeTab === "payments-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faMoneyBill} />
              </span>
              Payments and Settlements
            </button>
          </li>

          <li>
            <button onClick={() => navigate("/transaction-history")}className={activeTab === "transaction-history" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faExchangeAlt} />
              </span>
              Transactions History
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/whatsapp-management")} className={activeTab === "whatsapp-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faWhatsapp} />
                {/* <FontAwesomeIcon icon={['fab', 'whatsapp']} /> */}

              </span>
              Whatsapp Notifications Management
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/social-media-management")} className={activeTab === "social-media-management" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faPoll} />
              </span>
              Social Media Management
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/settings")} className={activeTab === "settings" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faCog} />
              </span>
              Settings
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/terms-conditions")} className={activeTab === "terms-conditions" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faClipboardList} />
              </span>
              Terms and Conditions
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/support")} className={activeTab === "support" ? "active" : ""}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              Support
            </button>
          </li>
          <li>
            <button onClick={() => navigate("/staff-management")}>
              <span className="icon me-2">
                <FontAwesomeIcon icon={faSignOutAlt} />
              </span>
              logout
            </button>
          </li>
        </ul>

        {/* 
        <Accordion defaultActiveKey="" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <FontAwesomeIcon className="icon" icon={faGauge} />
              Dashboard{" "}
            </Accordion.Header>
            <Accordion.Body>
              <ul className="sub-menu">
                <li>
                  <Link onClick={() => navigate("/staff-management")}>
                    <span className="icon">
                      {" "}
                      <FontAwesomeIcon icon={faPieChart} />
                    </span>
                    Dashboard One
                  </Link>
                </li>
                <li>
                  <Link onClick={() => navigate("/staff-management")}>
                    <span className="icon">
                      {" "}
                      <FontAwesomeIcon icon={faSquarePollVertical} />
                    </span>
                    Dashboard Two
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <FontAwesomeIcon className="icon" icon={faUtensils} />
              Manage Menu
            </Accordion.Header>
            <Accordion.Body>
              <ul className="sub-menu">
                <li>
                  <Link to="/food-category-list">
                    <span className="icon">
                      <FontAwesomeIcon icon={faClipboardList} />
                    </span>
                    Category List
                  </Link>
                </li>
                <li>
                  <Link to="/add-food-category">
                    <span className="icon">
                      <FontAwesomeIcon icon={faHotdog} />
                    </span>
                    Add Category
                  </Link>
                </li>
                <li>
                  <Link to="/menu-item-list">
                    <span className="icon">
                      <FontAwesomeIcon icon={faBowlFood} />
                    </span>
                    Food Items List
                  </Link>
                </li>
                <li>
                  <Link to="/add-menu-item">
                    <span className="icon">
                      <FontAwesomeIcon icon={faBurger} />
                    </span>
                    Add Food Item
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <FontAwesomeIcon className="icon" icon={faPaste} />
              Orders
            </Accordion.Header>
            <Accordion.Body>
              <ul className="sub-menu">
                <li>
                  <Link onClick={() => navigate("/staff-management")}>
                    <span className="icon">
                      {" "}
                      <FontAwesomeIcon icon={faFileLines} />
                    </span>
                    Orders
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <FontAwesomeIcon className="icon" icon={faUtensils} />
              Table{" "}
            </Accordion.Header>
            <Accordion.Body>
              <ul className="sub-menu">
                <li>
                  <Link to="/table-management">
                    <span className="icon">
                      <FontAwesomeIcon icon={faBarsProgress} />
                    </span>
                    Table Management
                  </Link>
                </li>
                <li>
                  <Link to="/table-list">
                    <span className="icon">
                      <FontAwesomeIcon icon={faLayerGroup} />
                    </span>
                    Table List
                  </Link>
                </li>
                <li>
                  <Link to="/add-table">
                    <span className="icon">
                      <FontAwesomeIcon icon={faTable} />
                    </span>
                    Add Table
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <FontAwesomeIcon className="icon" icon={faUsersCog} />
              Staff{" "}
            </Accordion.Header>
            <Accordion.Body>
              <ul className="sub-menu">
                <li>
                  <Link onClick={() => navigate("/staff-management")}>
                    <span className="icon">
                      {" "}
                      <FontAwesomeIcon icon={faUsers} />
                    </span>
                    Staff Listing
                  </Link>
                </li>
                <li>
                  <Link onClick={() => navigate("/staff-management")}>
                    <span className="icon">
                      {" "}
                      <FontAwesomeIcon icon={faUserPlus} />
                    </span>
                    Add Staff
                  </Link>
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
      </div>
    </div>
  );
}
