export const routes = {
  public: {
    signin: "/sign-in",
    signup: "/sign-up",
    forgotPassword: "/forgot-password",
    resetPassword: "/reset-password",
  },
  private: {
    dashboard: "/dashboard",
    foodCategoryList: "/food-category-list",
    viewFoodCategoryList: "/view-food-category-items/:id",
    addMenuItem: "/add-menu-item",
    editMenuItem: "/edit-menu-item/:id",
    addFoodCategory: "/add-food-category",
    editFoodCategory: "/edit-food-category/:id",
    viewMenuItemList: "/menu-item-list",
    viewTableList: "/table-list",
    addTable: "/add-table",
    tableManagement: "/table-management",
    menuManagement: "/menu-management",
    staffManagement: "/staff-management",
    staffListing: "/staff-listing",
    addStaff: "/add-staff",
    ordersManagement: "/orders-management",
    settings: "/settings",
    support: "/support",
    tableDashboard: "/table-dashboard",
    termsConditions: "/terms-conditions",
    transactionHistory: "/transaction-history",
    offersManagement: "/offers-management",
    addOffer: "/add-offer",
    inventoryManagement: "/inventory-management",
    addInventory: "/add-inventory",
    addInventoryItem: "/add-inventory-item",
    viewInventory: "/view-inventory",
    socialMediaManagement: "/social-media-management",
    qrcodemanagement: '/qr-code-generator',
    generateQR: "/generate-qr",
    downloadQR: "/download-qr",
    // menuManagement: "/menu-management",
    // menuManagement: "/menu-management",
    // menuManagement: "/menu-management",
    // menuManagement: "/menu-management",
    

  },
};
