import React from "react";
import useSignin from "./useSignin";
import { Form, Carousel, FloatingLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { routes } from "../../../routes/routes";
export const SignIn = () => {
  const { formData, formError, onFormInputChange, onSigninClick } = useSignin();

  return (
    <div className="common-login">
      <div className="row g-0">
        <div className="col-md-6">
          <div className="form-wrapper bg-white rounded-lg shadow d-flex flex-column justify-content-center align-items-center">
            <div className="form-inner">
              <div className="rest-logo text-center">
                <img src={"assets/images/waiter.png"} alt="" />
              </div>
              <h2 className="mb-3">Login</h2>
              <p className="mb-4">Welcome Back to Menuao!</p>
              <Form>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Username"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="name1234"
                    onChange={onFormInputChange}
                    name="username"
                    defaultValue={formData?.username}
                  />
                </FloatingLabel>
                {formError?.username && formError.username.length > 0 && (
                  <p className="text-danger mt-0 mb-0">{formError?.username}</p>
                )}
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Password"
                >
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={onFormInputChange}
                    name="password"
                    defaultValue={formData?.password}
                  />
                </FloatingLabel>
                {formError?.password && formError.password.length > 0 && (
                  <p className="text-danger mt-0 mb-0">{formError?.password}</p>
                )}
                <Form.Group className="mb-3">
                  <Form.Check
                    type="checkbox"
                    id="rememberme"
                    label="Remember Me"
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <button
                    className="btn btn-secondary btn-block w-100"
                    onClick={onSigninClick}
                  >
                    Login
                  </button>
                </Form.Group>

                <p className="fs-small text-center">
                  Forgot Password ?{" "}
                  <Link
                    to={routes.public.forgotPassword}
                    className="font-weight-semi text-underline"
                  >
                    click Here
                  </Link>{" "}
                </p>
                {/* <p className="fs-small text-center">
                  Don't have an account{" "}
                  <Link
                    to={routes.public.signup}
                    className="font-weight-semi text-underline"
                  >
                    Register Here
                  </Link>{" "}
                </p> */}
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6 left-content">
          <Carousel>
            <Carousel.Item>
              <img
                src={"/assets/images/login/slider-1.svg"}
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Fine Dining</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut,
                  est!
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"/assets/images/login/slider-2.svg"}
                alt="Second slide"
              />

              <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"/assets/images/login/slider-3.svg"}
                alt="Third slide"
              />

              <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};
