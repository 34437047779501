import { errorString } from "../schemas/errors";

export const formValidator = (formData, formSchema, excludedKeys) => {
  let error = {};
  let valid = { isValid: true };
  for (var key in formSchema) {
    if (key === excludedKeys) continue;
    else if (
      formData[key] === undefined ||
      formData[key] === "" ||
      formData[key] === 0 ||
      formData[key] === null
    ) {
      error[key] = errorString.emptyField;
      valid.isValid = false;
    }
  }
  return [valid.isValid, error];
};
