import React from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";

import { useNavigate } from "react-router-dom";
export default function StaffManagement() {
  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="staff-management" />
      <div className="dashboard-main-content menu-managment-main">
        <Header/>
        <div className="container">
          <div className="row justify-content-center pt-lg-5">
          
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/staff-listing")}
                  >
                    <div className="img">
                      <img src={"./assets/images/staff-listing.png"} alt="staff listing" />
                    </div>
                    <h4 className="text-secondary">View Staff Listing</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/add-staff")}
                  >
                    <div className="img">
                      <img src={"./assets/images/add-staff.png"} alt="add-staff" />
                    </div>
                    <h4 className="text-secondary">Add New Member</h4>
                  </div>
                </div>
               
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
