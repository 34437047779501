import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  create_category_details_user_action,
  delete_category_details_user_action,
  get_category_details_user_action,
  update_category_details_user_action,
} from "../_redux";

function useAddFoodCategory() {
  const [categoryDetailsForm, setCategoryDetailsForm] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { categoryDetailsData } = useSelector(
    (state) => state.get_category_details_user_reducer
  );

  useEffect(() => {
    const id = params?.id;

    if (id) {
      callApi(id);
    }
  }, []);

  useEffect(() => {
    if (categoryDetailsData?.data) {
      setCategoryDetailsForm(categoryDetailsData?.data);
    }
  }, [categoryDetailsData]);

  const callApi = async (id) => {
    await dispatch(get_category_details_user_action(id));
  };

  const onFormDataChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    let data = categoryDetailsForm

    data[name] = value
    setCategoryDetailsForm(data)
  }

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (categoryDetailsForm?.id) {
      await dispatch(
        update_category_details_user_action(
          categoryDetailsForm.id,
          categoryDetailsForm
        )
      );
    } else {
      await dispatch(create_category_details_user_action(categoryDetailsForm));
    }
  };

  const onCategoryDelete = async (e) => {
    e.preventDefault();
    if (categoryDetailsForm?.id) {
      await dispatch(
        delete_category_details_user_action(categoryDetailsForm.id)
      );
    }
  };

  const onEditClose = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return {
    categoryDetailsForm,

    onFormDataChange,
    onCategoryDelete,
    onFormSubmit,
    onEditClose,
  };
}

export default useAddFoodCategory;
