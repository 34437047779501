import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  create_menu_item_details_user_action,
  delete_menu_item_details_user_action,
  get_menu_item_details_user_action,
  update_menu_item_details_user_action,
} from "../_redux";

function useAddMenuItem() {
  const [menuItemDetailsForm, setMenuItemDetailsForm] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { menuItemDetailsData } = useSelector(
    (state) => state.get_menu_item_details_user_reducer
  );

  useEffect(() => {
    const id = params?.id;

    if (id) {
      callApi(id);
    }
  }, []);

  useEffect(() => {
    if (menuItemDetailsData?.data) {
      setMenuItemDetailsForm(menuItemDetailsData?.data);
    }
  }, [menuItemDetailsData]);

  const callApi = async (id) => {
    await dispatch(get_menu_item_details_user_action(id));
  };

  const onFormDataChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = menuItemDetailsForm;

    data[name] = value;
    setMenuItemDetailsForm(data);
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (menuItemDetailsForm?.id) {
      await dispatch(
        update_menu_item_details_user_action(
          menuItemDetailsForm.id,
          menuItemDetailsForm
        )
      );
    } else {
      await dispatch(create_menu_item_details_user_action(menuItemDetailsForm));
    }
  };

  const onMenuItemDelete = async (e) => {
    e.preventDefault();
    if (menuItemDetailsForm?.id) {
      await dispatch(
        delete_menu_item_details_user_action(menuItemDetailsForm.id)
      );
    }
  };

  const onEditClose = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return {
    menuItemDetailsForm,

    onFormDataChange,
    onMenuItemDelete,
    onFormSubmit,
    onEditClose,
  };
}

export default useAddMenuItem;
