import React, { useState } from "react";
import { Modal, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { fa1, fa2 } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import { useNavigate } from "react-router-dom";
import Pdf from "react-to-pdf";
import { QRCodeSVG } from "qrcode.react";


export default function DownloadQR() {
  const navigate = useNavigate();
  const ref = React.createRef();
  return (
    <div className="admin-dashboard-section download-qr-main">
      <Sidebar activeMenuItem="qr-code-generator" />
      <div className="dashboard-main-content tables-wapper">
        <Header />
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="d-flex align-items-center">
              <h4 className="text-primary me-2">Download QR for Restaurant</h4>
              <Pdf targetRef={ref} filename="code-example.pdf">
                {({ toPdf }) => <button className="btn btn-primary ms-auto" onClick={toPdf}>Download</button>}
              </Pdf>
            </div>
            <div ref={ref} className="p-4">
              <div className="bg-white qr-box-wrapper mt-4">
                <div className="qr-box">
                  <div className="top d-flex align-items-start bg-white">
                    <div>
                      <h1 className="text-primary font-poppins font-weight-bolder">E-MENU</h1>
                      <p className="font-small mb-0">{"(For a contactless menu, scan QR)"}</p>
                    </div>
                    <div className="restaurant-logo ms-auto text-end">
                      <img src={"./assets/images/subway.png"} alt="company logo" />
                      <h6>Table No. 11</h6>
                    </div>
                  </div>
                  <div className="middle text-center bg-primary text-white">
                    <p className="d-flex align-items-center justify-content-center">
                      <span className="me-2 h5 mb-0" >1</span>
                      Scan the QR Code
                    </p>
                    <div className="qr-code text-center my-4">
                      <QRCodeSVG value={"www.menulao.com/food-menu"} fgColor="#ffffff"
                        bgColor="#252939"
                        size="200"
                        level="M"
                      />
                    </div>
                    <p className="d-flex align-items-center justify-content-center">
                      <span className="me-2 h5 mb-0" >2</span>                      Order and Pay
                    </p>
                  </div>

                  <div className="bottom d-flex align-items-center">
                    <span className="fundine-logo">
                      <img src={"./assets/images/logo.png"} alt="company logo" />
                    </span>
                    <p className="ms-auto mb-0 text-end">Powered By: <strong className="text-primary">FunDine</strong></p>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>



      </div>


    </div>
  );
}
