import React from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
export default function Settings() {
  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="settings" />
      <div className="dashboard-main-content settings-main pb-4">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 mb-4">
              <div className="shadow bg-white">
                <div className="top p-3 border-bottom">
                  <h4>Settings</h4>
                </div>
                <div className="p-3">
                  <div className="form-group mb-3 img-upload-wrapper">
                    <label htmlFor="" className="font-weight-semi mb-2 d-block">Upload Logo</label>
                    <label htmlFor="RestaurantLogo" className="img-upload bg-grey p-3">
                      <input type="file" id="RestaurantLogo" className="d-none" />
                      <img src="./assets/images/photo-placeholder.png" alt="placeholder" />
                    </label>
                    <p className="">png, jpg files only...</p>
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi mb-2">Restaurant Name</label>
                    <input type="text" placeholder="Restaurant Name" className="form-control" />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi mb-2">Contact Number</label>
                    <input type="text" placeholder="Enter Contact Number" className="form-control" />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi mb-2">Alternative Contact Number</label>
                    <input type="text" placeholder="Enter Alternative Contact Number" className="form-control" />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi mb-2">Email Address</label>
                    <input type="email" placeholder="Enter Email Id" className="form-control" />
                  </div>
                  <div className="form-group mb-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="font-weight-semi mb-2">Address</label>
                          <input type="text" placeholder="Enter Address" className="form-control" />
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="font-weight-semi mb-2">City</label>
                          <select name="" id="" className="form-select">
                            <option value="">Select</option>
                            <option value="">Delhi</option>
                            <option value="">Mumbai</option>
                            <option value="">Kolkata</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="font-weight-semi mb-2">Country</label>
                          <select name="" id="" className="form-select bg-light">
                            <option value="">Select</option>
                            <option value="">India</option>
                            <option value="">Nepal</option>
                            <option value="">Bhutan</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="font-weight-semi mb-2">Pin Code</label>
                          <input type="text" placeholder="Pin Code" className="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="col-lg-12 mb-4">
              <div className="shadow bg-white">

                <div className="top p-3 border-bottom">
                  <h4>Restaurant Timings</h4>
                </div>
                <div className="p-3">
                  <div className="d-flex align-items-center rest-timing-box h6 mb-3 pb-3">
                    <span className="font-weight-bold day-name ">Day </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        Opening Timing
                      </div>
                      <div className="flex-grow-1 ms-2">
                        Closing Timing
                      </div>
                    </div>
                    <div className="availability ms-3">
                      Off
                    </div>
                  </div>
                  <div className="d-flex align-items-center rest-timing-box mb-3">
                    <span className="font-weight-bold day-name">MON </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        <input type="time" className="form-control" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="availability ms-3">
                      <Form.Group >

                        <Form.Check
                          type="switch"
                          id="EnableDiscount"

                          defaultChecked="checked"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex align-items-center rest-timing-box mb-3">
                    <span className="font-weight-bold day-name">TUE </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        <input type="time" className="form-control" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="availability ms-3">
                      <Form.Group >

                        <Form.Check
                          type="switch"
                          id="EnableDiscount"

                          defaultChecked="checked"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex align-items-center rest-timing-box mb-3">
                    <span className="font-weight-bold day-name">WED </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        <input type="time" className="form-control" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="availability ms-3">
                      <Form.Group >

                        <Form.Check
                          type="switch"
                          id="EnableDiscount"

                          defaultChecked="checked"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex align-items-center rest-timing-box mb-3">
                    <span className="font-weight-bold day-name">THU </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        <input type="time" className="form-control" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="availability ms-3">
                      <Form.Group >

                        <Form.Check
                          type="switch"
                          id="EnableDiscount"

                          defaultChecked="checked"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex align-items-center rest-timing-box mb-3">
                    <span className="font-weight-bold day-name">FRI </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        <input type="time" className="form-control" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="availability ms-3">
                      <Form.Group >

                        <Form.Check
                          type="switch"
                          id="EnableDiscount"

                          defaultChecked="checked"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex align-items-center rest-timing-box mb-3">
                    <span className="font-weight-bold day-name">SAT </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        <input type="time" className="form-control" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="availability ms-3">
                      <Form.Group >

                        <Form.Check
                          type="switch"
                          id="EnableDiscount"

                          defaultChecked="checked"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="d-flex align-items-center rest-timing-box mb-3">
                    <span className="font-weight-bold day-name">SUN </span>
                    <div className="d-flex flex-grow-1 ms-3">
                      <div className="flex-grow-1">
                        <input type="time" className="form-control" />
                      </div>
                      <div className="flex-grow-1 ms-2">
                        <input type="time" className="form-control" />
                      </div>
                    </div>
                    <div className="availability ms-3">
                      <Form.Group >

                        <Form.Check
                          type="switch"
                          id="EnableDiscount"

                          defaultChecked="checked"
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-12 action pt-3 border-top d-flex align-items-center justify-content-end">
                    <button className="btn btn-secondary">
                      Submit
                    </button>
                  </div>
                </div>
              </div>

            </div>

            {/* <div className="col-lg-12 mb-4">
              <div className="shadow bg-white">

                <div className="top p-3 border-bottom">
                  <h4>Banking Details</h4>
                </div>
                <div className="p-3">
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi mb-2">Account Number</label>
                    <input type="text" placeholder="Account Number" className="form-control" />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi mb-2">Account Name </label>
                    <input type="text" placeholder="Enter Account Name" className="form-control" />
                  </div>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="font-weight-semi mb-2">IFSC Code</label>
                    <input type="text" placeholder="Enter IFSC Code" className="form-control" />
                  </div>
                  <div className="form-group mb-3">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="font-weight-semi mb-2">UPI Id</label>
                          <input type="text" placeholder="Enter UPI Id" className="form-control" />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mb-3">
                          <button className="btn btn-secondary btn-sm">Add More UPI </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
