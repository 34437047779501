import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages } from "@fortawesome/free-solid-svg-icons";
import useAddFoodCategory from "./useAddFoodCategory";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
export const AddFoodCategory = (props) => {
  const {
    categoryDetailsForm,
    onFormDataChange,
    onCategoryDelete,
    onFormSubmit,
    onEditClose,
  } = useAddFoodCategory(props);

  return (
    <>
      <div className="admin-dashboard-section pg-add-food-item pb-5">
        <Sidebar activeMenuItem="menu-management" />
        <div className="dashboard-main-content">
          <Header pageTitle="Add Food Item" />

          <div className="form-wrapper">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 mb-4">
                  <h3 className="text-primary">Add New Food Category</h3>
                </div>
              </div>
              <Form>
                <div className="row justify-content-center">
                  <div className="col-lg-10 ">
                    <div className="bg-white shadow rounded-lg p-3 mb-4">
                      <div class="border-bottom pb-3 mb-4">
                        <h5 className="text-primary">Food Category Information</h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Category Id</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Category Id"
                              defaultValue={categoryDetailsForm?.id}
                              disabled
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Item Name"
                              name="name"
                              defaultValue={categoryDetailsForm?.name}
                              onChange={onFormDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-12">
                          <Form.Group className="mb-3">
                            <Form.Label>Item Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Description"
                              name="description"
                              defaultValue={categoryDetailsForm?.description}
                              onChange={onFormDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Select Existing Food Item</Form.Label>
                            <Form.Select className="form-control">
                              <option>Select</option>
                              <option value="1">Burgers</option>
                              <option value="2">Pizzas</option>
                              <option value="3">Grills</option>
                              <option value="3">Chinese</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-lg-12">
                          <Form.Group className="mb-3">
                            <button className="btn btn-primary btn-sm">
                              + Add More Item
                            </button>
                          </Form.Group>
                        </div>
                      </div>
                    </div>

                    <div className="bg-white shadow rounded-lg p-3 mb-4">
                      <div class="border-bottom pb-3 mb-4">
                        <h5 className="text-primary">Additonal Information</h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mb-3 img-upload-wrapper">
                            <label htmlFor="" className="font-weight-semi mb-2 d-block">Upload Food Image</label>
                            <label htmlFor="RestaurantLogo" className="img-upload bg-grey p-3">
                              <input type="file" id="RestaurantLogo" className="d-none" />
                              <img src="./assets/images/photo-placeholder.png" alt="placeholder" />
                            </label>
                            <p className="">png, jpg files only...</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Enable Discount on Category</Form.Label>
                            <Form.Check
                              type="switch"
                              id="EnableDiscount"
                              label="On/Off"
                              defaultChecked="checked"
                            />{" "}
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Discount Offer</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="in (%)"
                              max={100}
                              name="discount"
                              defaultValue={categoryDetailsForm?.discount}
                              onChange={onFormDataChange}
                            />
                          </Form.Group>
                        </div>

                        <div className="col-lg-12 action d-flex align-items-center justify-content-end pt-3 border-top">
                          <button className="btn btn-secondary me-3" onClick={onFormSubmit}>
                            Submit{" "}
                          </button>
                          <button
                            className="btn btn-danger me-3"
                            onClick={onCategoryDelete}
                          >
                            Delete
                          </button>
                          <button className="btn btn-primary" onClick={onEditClose}>
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
