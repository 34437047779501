import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faPlus,
  faStopwatch,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
export const TableManagement = () => {
  const [viewOrderModal, SetViewOrderModal] = useState(false);
  const [viewInstructionsModal, SetViewInstructionsModal] = useState(false);
  const [viewOrderStatusModal, SetViewOrderStatusModal] = useState(false);
  const [bookingRequestModal, SetbookingRequestModal] = useState(false);
  const [newItemAdditionModal, SetNewItemAdditionModal] = useState(false);

  const viewOrderModalHandler = () => {
    SetViewOrderModal(!viewOrderModal);
  };
  const viewInstructionsModalHandler = () => {
    SetViewInstructionsModal(!viewInstructionsModal);
  };
  const viewOrderStatusModalHandler = () => {
    SetViewOrderStatusModal(!viewOrderStatusModal);
  };
  const bookingRequestModalHandler = () => {
    SetbookingRequestModal(!bookingRequestModal);
  };
  const newItemAdditionModalHandler = () => {
    SetNewItemAdditionModal(!newItemAdditionModal);
  };
  return (
    <div className="admin-dashboard-section ">
      <Sidebar activeMenuItem="table-management" />
      <div className="dashboard-main-content tables-wrapper">
        <Header />
        <div className="main-content">
          <div className="container">
            <div className="page-body">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-6">
                  <div className="common-search">
                    <Form>
                      <Form.Group>
                        <Form.Control
                          type="search"
                          className="h-auto py-3 rounded-0 bg-white"
                          placeholder="Search Table ..."
                        />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className="col-md-3 text-md-end">
                  <Link to="/add-table" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add New
                  </Link>
                </div>
              </div>
              <div className="tables-list mt-5">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="table-item rounded-lg border-start border-danger bg-white overflow-hidden shadow">
                      <div className="top p-2 border-bottom bg-light text-primary">
                        <h5 className="d-flex align-items-center justify-content-between mb-2">
                          Table No <span className="ms-2">1</span>
                        </h5>
                        <h6 className="d-flex align-items-center justify-content-between">
                          Capacity <span className="ms-2">8</span>
                        </h6>
                      </div>
                      <div className="bottom p-2">
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Status:{" "}
                          <span className="ms-1 text-danger">occupied</span>
                        </p>
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Order Status{" "}
                          <span className="ms-1 text-warning">
                            Confirmed
                            <span
                              className="text-green text-underline cursor-pointer ms-2"
                              onClick={viewOrderStatusModalHandler}
                            >
                              View
                            </span>
                          </span>
                        </p>
                        <p className="d-flex align-items-center justify-content-between mb-0 font-weight-semi fs-small">
                          {" "}
                          <span className=" d-flex align-items-center">
                            {" "}
                            Assigned to :
                          </span>{" "}
                          <span className="ms-1">me. Vikas</span>{" "}
                        </p>
                        <p className="d-flex align-items-center justify-content-between text-warning fs-small font-weight-semi ">
                          <span className="blink d-flex align-items-center">
                            <img
                              src={"assets/images/shopping-basket.png"}
                              className="me-2"
                              alt="waiter"
                            />
                            New Item Added
                          </span>
                          <span
                            className="text-success text-underline cursor-pointer"
                            onClick={newItemAdditionModalHandler}
                          >
                            View
                          </span>
                        </p>
                        <p className="text-center">
                          <span
                            className="text-underline text-success font-weight-semi cursor-pointer"
                            onClick={viewOrderModalHandler}
                          >
                            View Order
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="table-item rounded-lg border-start border-success bg-white h-100 overflow-hidden shadow">
                      <div className="top p-2  border-bottom bg-light text-primary">
                        <h5 className="d-flex align-items-center justify-content-between mb-2">
                          Table No <span className="ms-2">2</span>
                        </h5>
                        <h6 className="d-flex align-items-center justify-content-between">
                          Capacity <span className="ms-2">8</span>
                        </h6>
                      </div>
                      <div className="bottom p-2">
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Table Status:{" "}
                          <span className="ms-1 text-success">Available</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="table-item rounded-lg border-start border-warning bg-white  overflow-hidden h-100 shadow">
                      <div className="top p-2  border-bottom bg-light text-primary">
                        <h5 className="d-flex align-items-center justify-content-between mb-2">
                          Table No <span className="ms-2">3</span>
                        </h5>
                        <h6 className="d-flex align-items-center justify-content-between">
                          Capacity <span className="ms-2">8</span>
                        </h6>
                      </div>
                      <div className="bottom p-2">
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Table Status:{" "}
                          <span className="ms-1 text-warning">
                            Request Received
                          </span>
                        </p>
                        <p className="text-center">
                          <span
                            className="text-underline text-success  font-weight-semi cursor-pointer"
                            onClick={bookingRequestModalHandler}
                          >
                            View Request
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="table-item rounded-lg border-start border-danger bg-white overflow-hidden h-100 shadow">
                      <div className="top  border-bottom bg-light text-primary p-2">
                        <h5 className="d-flex align-items-center justify-content-between mb-2">
                          Table No <span className="ms-2">4</span>
                        </h5>
                        <h6 className="d-flex align-items-center justify-content-between">
                          Capacity <span className="ms-2">8</span>
                        </h6>
                      </div>
                      <div className="bottom p-2">
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Status:{" "}
                          <span className="ms-1 text-danger">occupied</span>
                        </p>
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Order Status{" "}
                          <span className="ms-1 text-warning">Pending</span>{" "}
                        </p>
                        <p className="d-flex align-items-center text-danger fs-small font-weight-semi blink">
                          <img
                            src={"assets/images/waiter-assisted.png"}
                            className="me-2"
                            alt="waiter"
                          />{" "}
                          Assistance needed
                        </p>
                        <p className="text-center">
                          <span className="text-underline text-success  font-weight-semi cursor-pointer">
                            View Order
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="table-item rounded-lg border-start border-warning bg-white  overflow-hidden h-100 shadow">
                      <div className="top  border-bottom bg-light text-primary p-2">
                        <h5 className="d-flex align-items-center justify-content-between mb-2">
                          Table No <span className="ms-2">3</span>
                        </h5>
                        <h6 className="d-flex align-items-center justify-content-between">
                          Capacity <span className="ms-2">8</span>
                        </h6>
                      </div>
                      <div className="bottom p-2">
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Table Status:{" "}
                          <span className="ms-1 text-warning">
                            Request Received
                          </span>
                        </p>
                        <p className="text-center">
                          <span
                            className="text-underline text-success  font-weight-semi cursor-pointer"
                            onClick={bookingRequestModalHandler}
                          >
                            View Request
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    <div className="table-item rounded-lg border-start border-warning bg-white  overflow-hidden h-100 shadow">
                      <div className="top  border-bottom bg-light text-primary p-2">
                        <h5 className="d-flex align-items-center justify-content-between mb-2">
                          Table No <span className="ms-2">3</span>
                        </h5>
                        <h6 className="d-flex align-items-center justify-content-between">
                          Capacity <span className="ms-2">8</span>
                        </h6>
                      </div>
                      <div className="bottom p-2">
                        <p className="d-flex justify-content-between mb-0 font-weight-semi fs-small">
                          Table Status:{" "}
                          <span className="ms-1 text-warning">
                            Request Received
                          </span>
                        </p>
                        <p className="text-center">
                          <span
                            className="text-underline text-success  font-weight-semi cursor-pointer"
                            onClick={bookingRequestModalHandler}
                          >
                            View Request
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* change category Modal */}
      <Modal
        size="lg"
        show={viewOrderModal}
        onHide={viewOrderModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>
            <h4>View Order (Table No. 1)</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <ul className="p-3">
            <li className="border-bottom py-1 border-thick text-primary border-secondary">
              <div className="row mb-0 font-weight-bold ">
                <div className="col-4">Item</div>
                <div className="col-2 text-center">Quantity</div>
                <div className="col-3 text-center">Price</div>
                <div className="col-3 text-right">Total Price</div>
              </div>
            </li>
            <li className="border-bottom py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Burger</span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Red Sauce</span>{" "}
                  </span>
                  <span
                    className="text-primary text-underline line-height-1 fs-small d-block cursor-pointer"
                    onClick={viewInstructionsModalHandler}
                  >
                    see instructions
                  </span>
                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="border-bottom py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Pizza</span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Red Sauce</span>{" "}
                  </span>
                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="border-bottom py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">
                    Chicken Curry
                  </span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Extra Sauce</span>{" "}
                  </span>
                </div>
                <div className="col-2 text-center">X 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="border-bottom py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Burger</span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Extra Cheese Slice</span>{" "}
                  </span>
                </div>
                <div className="col-2 text-center">X 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
          </ul>
          <ul className="bg-light p-3">
            <li className="font-weight-semi d-flex align-items-center justify-content-between">
              <span>Sub Total</span>
              <span className="ms-1">₹400</span>
            </li>

            <li className="fs-small  font-weight-medium text-success d-flex align-items-center justify-content-between">
              <span>Discount</span>
              <span className="ms-1">₹100</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>SGST@2.5%</span>
              <span className="ms-1">₹50</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>CGST@2.5%</span>
              <span className="ms-1">₹50</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>Service Tax@5%</span>
              <span className="ms-1">₹100</span>
            </li>
            <li className="font-weight-bold text-secondary d-flex align-items-center justify-content-between">
              <span>Sub Total</span>
              <span className="ms-1">₹400</span>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-primary" onClick={viewOrderModalHandler}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  change category  Modal */}

      {/* View Instructions Modal */}
      <Modal
        size="sm"
        show={viewInstructionsModal}
        onHide={viewInstructionsModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>
            <h4>Instructions</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolores,
            rem saepe magnam ut deleniti nihil. Quisquam quod voluptas deleniti
            nobis!
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewInstructionsModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  View Instructions  Modal */}

      {/* View Order Status Modal */}
      <Modal
        size="lg"
        show={viewOrderStatusModal}
        onHide={viewOrderStatusModalHandler}
        centered
        id="viewOrderStatusModal"
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>
            <h4>Order Status</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <h4 className="d-flex align-items-center justify-content-between mt-3 mb-4">
            <span>Table No.1</span>
            <span className="fs-small">
              <FontAwesomeIcon icon={faStopwatch} className="me-2" />
              10:00
            </span>
          </h4>

          <article class="card">
            <header class="card-header"> My Orders / Tracking </header>
            <div class="card-body">
              <h6>Order ID: OD45345345435</h6>
              <div class="track">
                <div class="step active">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />{" "}
                  </span>
                  <span class="text">Order confirmed</span>{" "}
                </div>
                <div class="step active">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />{" "}
                  </span>
                  <span class="text"> Food Preparing</span>{" "}
                </div>
                <div class="step">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span class="text">Ready to Serve</span>{" "}
                </div>
                <div class="step">
                  {" "}
                  <span class="icon">
                    {" "}
                    <FontAwesomeIcon icon={faCheck} />{" "}
                  </span>
                  <span class="text">Delivered</span>{" "}
                </div>
              </div>
            </div>
          </article>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewOrderStatusModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* View Order Status Modal */}

      {/*Booking Action Modal */}
      <Modal
        size="lg"
        show={bookingRequestModal}
        onHide={bookingRequestModalHandler}
        centered
        id="viewOrderStatusModal"
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>
            <h4>Order Request</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <h5 className="mb-4">
            Table Number 1 has placed booking request{" "}
            <span
              className="cursor-pointer text-primary font-weight-medium text-underline"
              onClick={viewOrderModalHandler}
            >
              View Order
            </span>
          </h5>
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Table Number
                  </Form.Label>
                  <Form.Control type="text" placeholder="1" disabled />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Take Action
                  </Form.Label>
                  <Form.Control as="select">
                    <option value="">Select</option>
                    <option value="Accept">Accept</option>
                    <option value="Pizzas">Reject</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Assign Waiter
                  </Form.Label>
                  <Form.Control as="select">
                    <option value="">Select</option>
                    <option value="">Mukesh</option>
                    <option value="">Kaushal</option>
                    <option value="">Aditya</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Assign Cook
                  </Form.Label>
                  <Form.Control as="select">
                    <option value="">Select</option>
                    <option value="">Mukesh</option>
                    <option value="">Kaushal</option>
                    <option value="">Aditya</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="mb-3">
                  <Form.Label className="mb-2 font-weight-medium pl-2">
                    Estimate Time (In Minutes)
                  </Form.Label>
                  <Form.Control
                    type="time"
                    placeholder="Esmitate Time (in Minutes)"
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={bookingRequestModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* Booking Action Modal */}

      {/* View Instructions Modal */}
      <Modal
        size="lg"
        show={newItemAdditionModal}
        onHide={newItemAdditionModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>
            <h4>Add Item</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <h5 className="mb-4">Table Number 1 has placed new add item</h5>
          <h6>Order ID: OD45345345435</h6>
          <ul className="p-3">
            <li className="border-bottom py-2 border-thick text-primary border-secondary">
              <div className="row mb-0 font-weight-bold ">
                <div className="col-4">Item</div>
                <div className="col-2 text-center">Quantity</div>
                <div className="col-3 text-center">Price</div>
                <div className="col-3 text-right">Total Price</div>
              </div>
            </li>
            <li className="border-bottom py-2">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Burger</span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Red Sauce</span>{" "}
                  </span>
                  <span
                    className="text-primary text-underline line-height-1 fs-small d-block cursor-pointer"
                    onClick={viewInstructionsModalHandler}
                  >
                    see instructions
                  </span>
                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="mb-1  py-2">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Pizza</span>
                  <span className="fs-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Red Sauce</span>{" "}
                  </span>
                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-secondary">Add</button>
          <button
            className="btn btn-primary"
            onClick={newItemAdditionModalHandler}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  View Instructions  Modal */}
    </div>
  );
};
