import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Form } from "react-bootstrap";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import useCategoryList from "./useCategoryList";
import { useNavigate } from "react-router-dom";
export const CategoryList = (props) => {
  const { categoryList, onViewCategoryClick, onCategoryEditClick } =
    useCategoryList(props);
  const navigate = useNavigate();
  return (
    <>
      <div className="admin-dashboard-section pg-category-list">
        <Sidebar activeMenuItem="menu-management" />
        <div className="dashboard-main-content">
          <Header pageTitle="Category List" />
          <div className="main-content">
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-md-6">
                        <div className="common-search">
                          <Form>
                            <Form.Group>
                              <Form.Control
                                type="search"
                                className="h-auto py-3 bg-white rounded-0"
                                placeholder="Search Category..."
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                      <div className="col-md-3 text-md-end">
                        <button className="btn btn-primary" onClick={()=>{navigate("/add-food-category")}}>
                          {/* <FontAwesomeIcon icon={faPlus}/> */}+ Add New
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="food-category-list">
                <div className="row">
                  {categoryList &&
                    categoryList.map((item) => (
                      <div className="col-lg-3 col-md-4 mb-5" key={item?.id}>
                        <div className="food-category bg-white shadow position-relative">
                          <div className="tag bg-success text-white font-weight-bold font-small">
                            25% Off
                          </div>


                          <div className="cover-img mb-2">
                            <img
                              src={item?.image ? item?.image : "./assets/images/norecipes-home.png"}
                              className="w-100"
                              alt="burgers"
                            />
                          </div>
                          <div className="content  p-2 px-3 mb-2">
                            <h4 className="text-primary text-capitalize category-name mb-3">
                              {item?.name}
                            </h4>
                            <div className="d-flex align-items-center justify-content-between">

                              {/* <span className="text-muted ms-auto font-weight-semi">
                                {item?.food_items}
                              </span> */}
                              <button
                                onClick={() => onViewCategoryClick(item?.id)}
                                className="btn btn-secondary btn-sm me-2"
                                title="View"
                              >
                                View
                              </button>

                              <button className="btn btn-primary btn-sm">
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  onClick={() => onCategoryEditClick(item?.id)}
                                  className="me-2"
                                /> Edit
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
