import { categoryConstants } from "../_constants";
import axiosInstance from "../../../../_config/AxiosInstance";
import config from "../../../../_config/config";
// login
export const create_category_details_user_action =
  (data) => async (dispatch) => {
    dispatch({
      type: categoryConstants.CREATE_CATEGORY_DETAILS_USER_ACTION_REQUEST,
    });
    try {
      const response = await axiosInstance.post(
        config.category.createCategory,
        data
      );
      dispatch({
        type: categoryConstants.CREATE_CATEGORY_DETAILS_USER_ACTION_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: categoryConstants.CREATE_CATEGORY_DETAILS_USER_ACTION_FAILURE,
        payload: e,
      });
      return e;
    }
  };

export const get_category_details_user_action = (id) => async (dispatch) => {
  dispatch({
    type: categoryConstants.GET_CATEGORY_DETAILS_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      config.category.categoryByid.replace(":id", id)
    );
    dispatch({
      type: categoryConstants.GET_CATEGORY_DETAILS_USER_ACTION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: categoryConstants.GET_CATEGORY_DETAILS_USER_ACTION_FAILURE,
      payload: e,
    });
    return e;
  }
};

export const update_category_details_user_action =
  (id, data) => async (dispatch) => {
    dispatch({
      type: categoryConstants.UPDATE_CATEGORY_DETAILS_USER_ACTION_REQUEST,
    });
    try {
      const response = await axiosInstance.put(
        config.category.categoryByid.replace(":id", id),
        data
      );
      dispatch({
        type: categoryConstants.UPDATE_CATEGORY_DETAILS_USER_ACTION_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: categoryConstants.UPDATE_CATEGORY_DETAILS_USER_ACTION_FAILURE,
        payload: e,
      });
      return e;
    }
  };

export const delete_category_details_user_action = (id) => async (dispatch) => {
  dispatch({
    type: categoryConstants.DELETE_CATEGORY_DETAILS_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.delete(
      config.category.categoryByid.replace(":id", id)
    );
    dispatch({
      type: categoryConstants.DELETE_CATEGORY_DETAILS_USER_ACTION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: categoryConstants.DELETE_CATEGORY_DETAILS_USER_ACTION_FAILURE,
      payload: e,
    });
    return e;
  }
};
