import React from "react";
import { Form, Carousel, FloatingLabel } from "react-bootstrap";
import { Link } from "react-router-dom";
export const ForgotPassword = () => {
  return (
    <div className="common-login">
      <div className="row g-0">
        <div className="col-md-6">
          <div className="form-wrapper bg-white rounded-lg shadow d-flex flex-column justify-content-center align-items-center">
            <div className="form-inner">
              <div className="rest-logo text-center">
                <img src={"assets/images/waiter.png"} alt="" />
              </div>
              <h2 className="mb-3">Forgot Password</h2>
              <p className="mb-4">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Quibusdam, provident!!
              </p>
              <Form>
                <FloatingLabel className="mb-3" controlId="Email" label="Email">
                  <Form.Control type="password" placeholder="Enter Password" />
                </FloatingLabel>

                <Form.Group className="mb-3">
                  <button className="btn btn-secondary btn-block w-100">
                    Reset
                  </button>
                </Form.Group>

                <p className="fs-small text-center">
                  Already have an account{" "}
                  <Link className="font-weight-semi text-underline">
                    Log In
                  </Link>{" "}
                </p>
              </Form>
            </div>
          </div>
        </div>
        <div className="col-md-6 left-content">
          <Carousel>
            <Carousel.Item>
              <img
                src={"/assets/images/login/slider-1.svg"}
                alt="First slide"
              />
              <Carousel.Caption>
                <h3>Fine Dining</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut,
                  est!
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"/assets/images/login/slider-2.svg"}
                alt="Second slide"
              />

              <Carousel.Caption>
                <h3>Second slide label</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                src={"/assets/images/login/slider-3.svg"}
                alt="Third slide"
              />

              <Carousel.Caption>
                <h3>Third slide label</h3>
                <p>
                  Praesent commodo cursus magna, vel scelerisque nisl
                  consectetur.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};
