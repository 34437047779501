import React, { useState } from "react";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencil,
  faTrash,
  faStar,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function StaffListing() {
  const [deletePopupModal, setDeletePopupModal] = useState(false);
  const [viewStaffModal, setViewStaffModal] = useState(false);
  const [viewRatingsModal, setViewRatingsModal] = useState(false);

  const deletePopupModalHandler = () => {
    setDeletePopupModal(!deletePopupModal);
  };
  const viewStaffModalHandler = () => {
    setViewStaffModal(!viewStaffModal);
  };
  const viewRatingsModalHandler = () => {
    setViewRatingsModal(!viewRatingsModal);
  };


  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="staff-management" />
      <div className="dashboard-main-content staff-listing-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-12">
              <div className="">
                <div className="row justify-content-between align-items-center">
                  <div className="col-md-6">
                    <div className="common-search">
                      <Form>
                        <Form.Group>
                          <Form.Control
                            type="search"
                            className="h-auto py-3 rounded-0 bg-white"
                            placeholder="Search Staff..."
                          />
                        </Form.Group>
                      </Form>
                    </div>
                  </div>
                  <div className="col-md-3 text-md-end">
                    <Link to="/add-staff" className="btn btn-primary">
                      <FontAwesomeIcon icon={faPlus} className="me-2" />
                      Add Staff
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white">
            <div className="filters  p-3 mb-4 border-bottom pb-3 bg-white  ">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <p className="font-weight-semi text-nowrap me-3 mb-0">
                    Filter By
                  </p>
                </div>
                <div className="col-md-6">
                  <div className="category d-flex align-items-center justify-content-end">
                    <p className="font-weight-semi text-nowrap me-3 mb-0">
                      Select By Designation
                    </p>
                    <select className="rounded h-auto p-2">
                      <option value="">All</option>
                      <option value="">Manager</option>
                      <option value="">Waiter</option>
                      <option value="">Cook</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="staff-list mt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-lg-4 mb-4">
                    {/* staff item  */}
                    <div className="staff-item bg-light border shadow-sm p-4 rounded-lg text-center">
                      <div className="d-flex align-items-center mb-2">
                        <div className="bg-success rating text-white justify-content-center d-flex align-items-center">
                          <FontAwesomeIcon icon={faStar} className="fs-small" />
                          <span className="ms-2 font-weight-semi">3.5</span>
                        </div>
                      </div>

                      <div className="staff-img mb-3">
                        <img
                          src={"assets/images/avatar2.png"}
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="bottom">
                        <h5 className="text-secondary text-capitalize mb-1">
                          Mukesh Singh Negi
                        </h5>{" "}
                        <p className="font-weight-semi mb-1 text-primary">
                          (Manager)
                        </p>
                        <ul className="line-height-1  mb-4">
                          <li className="mb-2 fs-small font-weight-semi text-primary">
                            (Employe Id: 0001)
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Phone:</span>{" "}
                            9999999999
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Email:</span>{" "}
                            employee1@gmail.com
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi"> Address: </span>12
                            Block-14, South Campus, New Delhi
                          </li>
                          <li></li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-secondary"
                            onClick={viewStaffModalHandler}
                          >
                            <span className="me-3"><FontAwesomeIcon icon={faEye} /></span>
                            View
                          </button>

                        </div>
                      </div>
                    </div>
                    {/* staff item  */}
                  </div>

                  <div className="col-md-6 col-lg-4 mb-4">
                    {/* staff item  */}
                    <div className="staff-item bg-light border shadow-sm p-4 rounded-lg text-center">
                      <div className="d-flex align-items-center mb-2">
                        <div className="bg-success rating text-white justify-content-center d-flex align-items-center">
                          <FontAwesomeIcon icon={faStar} className="fs-small" />
                          <span className="ms-2 font-weight-semi">3.5</span>
                        </div>
                      </div>

                      <div className="staff-img mb-3">
                        <img
                          src={"assets/images/avatar2.png"}
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="bottom">
                        <h5 className="text-secondary text-capitalize mb-1">
                          Mukesh Singh Negi
                        </h5>{" "}
                        <p className="font-weight-semi mb-1 text-primary">
                          (Manager)
                        </p>
                        <ul className="line-height-1  mb-4">
                          <li className="mb-2 fs-small font-weight-semi text-primary">
                            (Employe Id: 0001)
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Phone:</span>{" "}
                            9999999999
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Email:</span>{" "}
                            employee1@gmail.com
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi"> Address: </span>12
                            Block-14, South Campus, New Delhi
                          </li>
                          <li></li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-secondary"
                            onClick={viewStaffModalHandler}
                          >
                            <span className="me-3"><FontAwesomeIcon icon={faEye} /></span>
                            View
                          </button>

                        </div>
                      </div>
                    </div>
                    {/* staff item  */}
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    {/* staff item  */}
                    <div className="staff-item bg-light border shadow-sm p-4 rounded-lg text-center">
                      <div className="d-flex align-items-center mb-2">
                        <div className="bg-success rating text-white justify-content-center d-flex align-items-center">
                          <FontAwesomeIcon icon={faStar} className="fs-small" />
                          <span className="ms-2 font-weight-semi">3.5</span>
                        </div>
                      </div>

                      <div className="staff-img mb-3">
                        <img
                          src={"assets/images/avatar2.png"}
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="bottom">
                        <h5 className="text-secondary text-capitalize mb-1">
                          Mukesh Singh Negi
                        </h5>{" "}
                        <p className="font-weight-semi mb-1 text-primary">
                          (Manager)
                        </p>
                        <ul className="line-height-1  mb-4">
                          <li className="mb-2 fs-small font-weight-semi text-primary">
                            (Employe Id: 0001)
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Phone:</span>{" "}
                            9999999999
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Email:</span>{" "}
                            employee1@gmail.com
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi"> Address: </span>12
                            Block-14, South Campus, New Delhi
                          </li>
                          <li></li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-secondary"
                            onClick={viewStaffModalHandler}
                          >
                            <span className="me-3"><FontAwesomeIcon icon={faEye} /></span>
                            View
                          </button>

                        </div>
                      </div>
                    </div>
                    {/* staff item  */}
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    {/* staff item  */}
                    <div className="staff-item bg-light border shadow-sm p-4 rounded-lg text-center">
                      <div className="d-flex align-items-center mb-2">
                        <div className="bg-success rating text-white justify-content-center d-flex align-items-center">
                          <FontAwesomeIcon icon={faStar} className="fs-small" />
                          <span className="ms-2 font-weight-semi">3.5</span>
                        </div>
                      </div>

                      <div className="staff-img mb-3">
                        <img
                          src={"assets/images/avatar2.png"}
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="bottom">
                        <h5 className="text-secondary text-capitalize mb-1">
                          Mukesh Singh Negi
                        </h5>{" "}
                        <p className="font-weight-semi mb-1 text-primary">
                          (Manager)
                        </p>
                        <ul className="line-height-1  mb-4">
                          <li className="mb-2 fs-small font-weight-semi text-primary">
                            (Employe Id: 0001)
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Phone:</span>{" "}
                            9999999999
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Email:</span>{" "}
                            employee1@gmail.com
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi"> Address: </span>12
                            Block-14, South Campus, New Delhi
                          </li>
                          <li></li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-secondary"
                            onClick={viewStaffModalHandler}
                          >
                            <span className="me-3"><FontAwesomeIcon icon={faEye} /></span>
                            View
                          </button>

                        </div>
                      </div>
                    </div>
                    {/* staff item  */}
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    {/* staff item  */}
                    <div className="staff-item bg-light border shadow-sm p-4 rounded-lg text-center">
                      <div className="d-flex align-items-center mb-2">
                        <div className="bg-success rating text-white justify-content-center d-flex align-items-center">
                          <FontAwesomeIcon icon={faStar} className="fs-small" />
                          <span className="ms-2 font-weight-semi">3.5</span>
                        </div>
                      </div>

                      <div className="staff-img mb-3">
                        <img
                          src={"assets/images/avatar2.png"}
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="bottom">
                        <h5 className="text-secondary text-capitalize mb-1">
                          Mukesh Singh Negi
                        </h5>{" "}
                        <p className="font-weight-semi mb-1 text-primary">
                          (Manager)
                        </p>
                        <ul className="line-height-1  mb-4">
                          <li className="mb-2 fs-small font-weight-semi text-primary">
                            (Employe Id: 0001)
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Phone:</span>{" "}
                            9999999999
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Email:</span>{" "}
                            employee1@gmail.com
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi"> Address: </span>12
                            Block-14, South Campus, New Delhi
                          </li>
                          <li></li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-secondary"
                            onClick={viewStaffModalHandler}
                          >
                            <span className="me-3"><FontAwesomeIcon icon={faEye} /></span>
                            View
                          </button>

                        </div>
                      </div>
                    </div>
                    {/* staff item  */}
                  </div>
                  <div className="col-md-6 col-lg-4 mb-4">
                    {/* staff item  */}
                    <div className="staff-item bg-light border shadow-sm p-4 rounded-lg text-center">
                      <div className="d-flex align-items-center mb-2">
                        <div className="bg-success rating text-white justify-content-center d-flex align-items-center">
                          <FontAwesomeIcon icon={faStar} className="fs-small" />
                          <span className="ms-2 font-weight-semi">3.5</span>
                        </div>
                      </div>

                      <div className="staff-img mb-3">
                        <img
                          src={"assets/images/avatar2.png"}
                          alt=""
                          className="w-100"
                        />
                      </div>
                      <div className="bottom">
                        <h5 className="text-secondary text-capitalize mb-1">
                          Mukesh Singh Negi
                        </h5>{" "}
                        <p className="font-weight-semi mb-1 text-primary">
                          (Manager)
                        </p>
                        <ul className="line-height-1  mb-4">
                          <li className="mb-2 fs-small font-weight-semi text-primary">
                            (Employe Id: 0001)
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Phone:</span>{" "}
                            9999999999
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi">Email:</span>{" "}
                            employee1@gmail.com
                          </li>
                          <li className="mb-2 fs-small">
                            <span className="font-weight-semi"> Address: </span>12
                            Block-14, South Campus, New Delhi
                          </li>
                          <li></li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-secondary"
                            onClick={viewStaffModalHandler}
                          >
                            <span className="me-3"><FontAwesomeIcon icon={faEye} /></span>
                            View
                          </button>

                        </div>
                      </div>
                    </div>
                    {/* staff item  */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <Modal
        size="sm"
        show={deletePopupModal}
        onHide={deletePopupModalHandler}
        centered
      >
        <Modal.Header closeButton >
          <Modal.Title>
            <h4 > Delete staff</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Do you really want to delete this staff?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-secondary"
            onClick={deletePopupModalHandler}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={deletePopupModalHandler}
          >
            Delete
          </button>

        </Modal.Footer>
      </Modal>

      {/* view staff details */}
      <Modal
        size="lg"
        show={viewStaffModal}
        onHide={viewStaffModalHandler}
        centered
        className="view-staff-modal"
      >
        <Modal.Header closeButton className="border-bottom-0 bg-transparent pb-0">
          <Modal.Title>
            {/* <h4 className="text-secondary">View Staff</h4> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="container">
            <div className="row">
              <div className="col-lg-4  text-white staff-brief-wrapper ">
                <div className="staff-brief p-4 text-center">
                  <div className="staff-img mb-3">
                    <img src={"/assets/images/avatar2.png"} alt="" />
                  </div>
                  <h5 className="mb-2">Mukesh Singh Negi</h5>
                  <h6>Manager</h6>
                </div>
              </div>
              <div className="col-lg-8">
                <h3 className="text-primary mb-3">Staff Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Name
                    </p>
                    <p className="fs-small mb-1">Mukesh Singh Negi</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p className="font-weight-semi text-primary mb-1">
                      Designation
                    </p>
                    <p className="fs-small mb-1">Manager</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p className="font-weight-semi text-primary mb-1">
                      Employee Id
                    </p>
                    <p className="fs-small mb-1">00001</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Contact Number
                    </p>
                    <p className="fs-small mb-1">9999999999</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Emergency Contact Number
                    </p>
                    <p className="fs-small mb-1">8888888888</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p className="font-weight-semi text-primary mb-1">
                      Address
                    </p>
                    <p className="fs-small mb-1">
                      12 Block-14, South Campus, New Delhi-100001
                    </p>{" "}
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Date of Birth (Age)
                    </p>
                    <p className="fs-small mb-1">01/02/2000 (21)</p>
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Remuneration
                    </p>
                    <p className="fs-small mb-1">₹10,000</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Id Type
                    </p>
                    <p className="fs-small mb-1">Aadhaar Card</p>
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Id Number
                    </p>
                    <p className="fs-small mb-1">4444 4444 4444</p>
                  </div>

                  <div className="col-lg-6">
                    <p className="font-weight-semi text-primary mb-1">
                      Ratings
                    </p>
                    <div className="d-flex align-items-center mb-2">
                      <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                        <span className="me-2 font-weight-semi">3.5</span>
                        <FontAwesomeIcon icon={faStar} className="fs-small" />
                      </div>
                      <span
                        className="fs-small text-underline cursor-pointer"
                        onClick={viewRatingsModalHandler}
                      >
                        View Ratings & Reviews
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <p className="font-weight-semi text-primary mb-1">
                      Work Experience
                    </p>
                    <p className="font-weight-semi  fs-small mb-1">
                      Hyatt Hotel, Delhi as Manager (2 Years)
                    </p>
                    <p className="fs-small mb-1">
                      Lorem, ipsum dolor sit amet consectetur adipisicing
                      elit. Neque nisi officia hic ab nam consectetur
                      voluptatum deserunt omnis expedita ipsum.
                    </p>{" "}
                    <p className="font-weight-semi  fs-small mb-1">
                      Radisson, Delhi as Manager (3 Years)
                    </p>
                    <p className="fs-small mb-1">
                      Lorem, ipsum dolor sit amet consectetur adipisicing
                      elit. Neque nisi officia hic ab nam consectetur
                      voluptatum deserunt omnis expedita ipsum.
                    </p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-secondary"
          >
            Edit
          </button>
          <button
            className="btn btn-danger"
            onClick={deletePopupModalHandler}
          >
            Delete
          </button>
          <button
            className="btn btn-primary"
            onClick={viewStaffModalHandler}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
      {/* view staff details */}

      {/* view ratings popup  */}
      <Modal
        size="md"
        show={viewRatingsModal}
        onHide={viewRatingsModalHandler}
        centered
        className="review-modal"
      >
        <Modal.Header closeButton className="border-bottom-0 ">
          <Modal.Title>
            <h4 className="text-primary">
              Customers Ratings{" "}
              <span className="font-small text-muted font-weight-medium">
                (5 Reviews & Ratings)
              </span>
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Mukesh Singh Negi
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">3.5</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
              <div className="pt-2">
                <p className="fs-small mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus quia consequuntur ipsum earum voluptatum, fugit
                  non culpa harum recusandae sequi?
                </p>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Kaushal Sharma
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">4</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
              <div className="pt-2">
                <p className="fs-small mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus quia consequuntur ipsum earum voluptatum, fugit
                  non culpa harum recusandae sequi?
                </p>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Aditya Singh Parmar
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">5</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
              <div className="pt-2">
                <p className="fs-small mb-0">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repellendus quia consequuntur ipsum earum voluptatum, fugit
                  non culpa harum recusandae sequi?
                </p>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Akshay Kumar
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">5</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
            </div>
          </div>
          <div className="review d-flex align-items-start mb-3">
            <div className="user me-3">
              <img src={"assets/images/user.png"} alt="" />
            </div>
            <div className="content flex-grow-1">
              <div className="top d-flex border-bottom pb-3">
                <span className="font-weight-semi me-3 text-primary">
                  Salman Khan
                </span>
                <div className="bg-success me-2 px-2 text-white rounded-pill d-flex align-items-center">
                  <span className="me-2 font-weight-semi">4</span>
                  <FontAwesomeIcon icon={faStar} className="fs-small" />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={viewRatingsModalHandler}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
      {/* view ratings popup  */}
    </div>
  );
}
