import React, { useState } from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Modal, Table } from "react-bootstrap";

export default function TransactionHistory() {
  const navigate = useNavigate();
  const [viewTransaction, SetViewTransaction] = useState(false);
  const [viewOrderModal, SetViewOrderModal] = useState(false);


  const viewTransactionHandler = () => {
    SetViewTransaction(!viewTransaction);
  };
  const viewOrderModalHandler = () => {
    SetViewOrderModal(!viewOrderModal);
  };

  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="transaction-history" />
      <div className="dashboard-main-content settings-main pb-4">
        <Header pageTitle="Dashboard" />
        <div className="container">


          <div className="bg-white px-3 shadow pb-3 mb-5">
            <div className="mb-3 py-3 border-bottom">
              <h4 className="text-primary">Transactions</h4>
            </div>

            <div className="custom-table-wrapper border">
              <Table responsive hover className="custom-table" >
                <thead>
                  <tr>
                    <th className="text-nowrap">Order No.</th>
                    <th>Date</th>
                    <th className="text-nowrap">Customer Name</th>
                    <th>Amount</th>
                    <th>Items</th>
                    <th>Type</th>
                    <th>Payment</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>12345</td>
                    <td>17/11/2022</td>
                    <td>Mukesh Singh</td>
                    <td>Rs. 120</td>
                    <td>2 Items</td>
                    <td>Dine In</td>
                    <td>Paytm</td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewTransactionHandler}
                      >View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>12345</td>
                    <td>17/11/2022</td>
                    <td>Mukesh Singh</td>
                    <td>Rs. 120</td>
                    <td>2 Items</td>
                    <td>Dine In</td>
                    <td>Paytm</td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewTransactionHandler}
                      >View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>12345</td>
                    <td>17/11/2022</td>
                    <td>Mukesh Singh</td>
                    <td>Rs. 120</td>
                    <td>2 Items</td>
                    <td>Dine In</td>
                    <td>Paytm</td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewTransactionHandler}
                      >View</button>
                    </td>
                  </tr>
                  <tr>
                    <td>12345</td>
                    <td>17/11/2022</td>
                    <td>Mukesh Singh</td>
                    <td>Rs. 120</td>
                    <td>2 Items</td>
                    <td>Dine In</td>
                    <td>Paytm</td>
                    <td>
                      <button className="btn btn-primary btn-sm"
                        onClick={viewTransactionHandler}
                      >View</button>
                    </td>
                  </tr>

                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>

      {/* Order Modal */}
      <Modal
        size="md"
        show={viewTransaction}
        onHide={viewTransactionHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom">
          <Modal.Title>
            <h4 className="text-primary">Offer Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="border p-3 bg-light rounded">
            <div className="row">
              <div className="col-6">
                <h6 className="text-primary">Order No.	</h6>
                <p className="font-weight-medium">4464644</p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Transaction Date</h6>
                <p className="font-weight-medium">22/11/2022
                </p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Customer Name	</h6>
                <p className="font-weight-medium">Mr. Mukesh Singh Negi</p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Amount Paid</h6>
                <p className="font-weight-medium">
                  Rs.200
                </p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Ordered Items</h6>
                <p className="font-weight-medium">2 Items
                  <span className="text-secondary text-underline font-weight-bold ms-2 cursor-pointer"
                    onClick={viewOrderModalHandler}
                  >View</span></p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Order Type</h6>
                <p className="font-weight-medium">Dine In</p>
              </div>
              <div className="col-6">
                <h6 className="text-primary">Mode of Payment</h6>
                <p className="font-weight-medium">Paytm</p>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-secondary" onClick={() => { navigate("/add-offer") }}>
            Edit
          </button>
          <button className="btn btn-primary" onClick={viewTransactionHandler}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/*  Order  Modal */}



      {/* order details  */}


      <Modal
        size="md"
        show={viewOrderModal}
        onHide={viewOrderModalHandler}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>
            <h4>Order Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <ul className="p-3">
            <li className="border-bottom py-2 border-thick text-primary border-secondary">
              <div className="row mb-0 font-weight-bold ">
                <div className="col-4">Item</div>
                <div className="col-2 text-center">Quantity</div>
                <div className="col-3 text-center">Price</div>
                <div className="col-3 text-right">Total Price</div>
              </div>
            </li>
            <li className="py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Burger</span>
                  <span className="font-small line-height-1">
                    <span className="text-primary d-block">Add Ons</span>{" "}
                    <span className="ms-2">Red Sauce</span>{" "}
                  </span>

                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Pizza</span>
                
                </div>
                <div className="col-2 text-center">x 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">
                    Chicken Curry
                  </span>
                
                </div>
                <div className="col-2 text-center">X 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
            <li className="py-1">
              <div className="row">
                <div className="col-4">
                  <span className="d-block font-weight-semi">Burger</span>
                 
                </div>
                <div className="col-2 text-center">X 2</div>
                <div className="col-3 text-center">₹200</div>
                <div className="col-3 text-right">₹400</div>
              </div>
            </li>
          </ul>
          <ul className="bg-light p-3">
            <li className="font-weight-semi d-flex align-items-center justify-content-between">
              <span>Sub Total</span>
              <span className="ms-1">₹400</span>
            </li>

            <li className="fs-small  font-weight-bold text-success d-flex align-items-center justify-content-between">
              <span>Discount</span>
              <span className="ms-1">₹100</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>SGST@2.5%</span>
              <span className="ms-1">₹50</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>CGST@2.5%</span>
              <span className="ms-1">₹50</span>
            </li>
            <li className="fs-small font-weight-medium d-flex align-items-center justify-content-between">
              <span>Service Tax@5%</span>
              <span className="ms-1">₹100</span>
            </li>
            <li className="font-weight-bold text-primary d-flex align-items-center justify-content-between">
              <span>Sub Total</span>
              <span className="ms-1">₹400</span>
            </li>
          </ul>
          <div className="d-flex bg-light p-3 border-top text-secondary font-weight-bold align-items-center justify-content-between">
            <span>Paid</span>
            <span>Via: Paytm</span>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-primary" onClick={viewOrderModalHandler}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {/* order details  */}

    </div>
  );
}
