import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { user_token } from "./_helpers/tokens/UserToken";
import { routes } from "./routes/routes";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { SignIn, SignUp, ForgotPassword, ResetPassword } from "./pages/Auth";
import Dashboard from "./pages/Dashboard";
import {
  AddFoodCategory,
  ViewCategoryList,
  CategoryList,
} from "./pages/Category";
import { MenuItemList, AddMenuItem } from "./pages/MenuItem";
import { TableList, AddTable, TableManagement, TableDashboard } from "./pages/Table";
import MenuManagement from "./pages/MenuManagement";
import StaffManagement from "./pages/StaffManagement";
import StaffListing from "./pages/StaffManagement/StaffListing";
import AddStaff from "./pages/StaffManagement/AddStaff";
import OrdersManagement from "./pages/OrdersManagement";
import Settings from "./pages/Settings";
import Support from "./pages/Support"
import TermsConditions from "./pages/TermsConditions";
import TransactionHistory from "./pages/TransactionHistory";
import OffersManagement from "./pages/OffersManagement";
import AddOffer from "./pages/OffersManagement/AddOffer";
import InventoryManagement from "./pages/InventoryManagement";
import AddInventory from "./pages/InventoryManagement/AddInventory";
import ViewInventory from "./pages/InventoryManagement/ViewInventory";
import AddInventoryItem from "./pages/InventoryManagement/AddInventoryItem";
import SocialMediaManagement from "./pages/SocialMediaManagement";
import QRCodeManagement from "./pages/QRCodeManagement";
import GenerateQR from "./pages/QRCodeManagement/GenerateQR";
import DownloadQR from "./pages/QRCodeManagement/DownloadQR";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={user_token === null ? <SignIn /> : <Dashboard />}
        />
        <Route path="/" element={<PublicRoute />}>
          <Route path={routes.public.signin} element={<SignIn />} />
          <Route path={routes.public.signup} element={<SignUp />} />
          <Route
            path={routes.public.forgotPassword}
            element={<ForgotPassword />}
          />
          <Route
            path={routes.public.resetPassword}
            element={<ResetPassword />}
          />
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path={routes.private.dashboard} element={<Dashboard />} />
          <Route
            path={routes.private.foodCategoryList}
            element={<CategoryList />}
          />
          <Route
            path={routes.private.viewFoodCategoryList}
            element={<ViewCategoryList />}
          />
          <Route path={routes.private.addMenuItem} element={<AddMenuItem />} />
          <Route path={routes.private.editMenuItem} element={<AddMenuItem />} />
          <Route
            path={routes.private.addFoodCategory}
            element={<AddFoodCategory />}
          />
          <Route
            path={routes.private.editFoodCategory}
            element={<AddFoodCategory />}
          />
          <Route
            path={routes.private.viewMenuItemList}
            element={<MenuItemList />}
          />
          <Route path={routes.private.viewTableList} element={<TableList />} />
          <Route path={routes.private.addTable} element={<AddTable />} />
          <Route
            path={routes.private.tableManagement}
            element={<TableManagement />}
          />
          <Route
            path={routes.private.menuManagement}
            element={<MenuManagement />}
          />
          <Route
            path={routes.private.staffManagement}
            element={<StaffManagement />}
          />

          <Route
            path={routes.private.staffListing}
            element={<StaffListing />}
          />
          <Route
            path={routes.private.addStaff}
            element={<AddStaff />}
          />
          <Route
            path={routes.private.ordersManagement}
            element={<OrdersManagement />}
          />
          <Route
            path={routes.private.settings}
            element={<Settings />}
          />
          <Route
            path={routes.private.support}
            element={<Support />}
          />
          <Route
            path={routes.private.termsConditions}
            element={<TermsConditions />}
          />
          <Route
            path={routes.private.tableDashboard}
            element={<TableDashboard />}
          />

          <Route
            path={routes.private.transactionHistory}
            element={<TransactionHistory />}
          />
          <Route
            path={routes.private.offersManagement}
            element={<OffersManagement />}
          />
          <Route
            path={routes.private.addOffer}
            element={<AddOffer />}
          />
          <Route
            path={routes.private.inventoryManagement}
            element={<InventoryManagement />}
          />
          <Route
            path={routes.private.menuManagement}
            element={<MenuManagement />}
          />
          <Route
            path={routes.private.addInventory}
            element={<AddInventory />}
          />
           <Route
            path={routes.private.addInventoryItem}
            element={<AddInventoryItem />}
          />
          <Route
            path={routes.private.viewInventory}
            element={<ViewInventory />}
          />
          <Route
            path={routes.private.socialMediaManagement}
            element={<SocialMediaManagement />}
          /> 
          <Route
            path={routes.private.qrcodemanagement}
            element={<QRCodeManagement />}
          /> 
          <Route
            path={routes.private.generateQR}
            element={<GenerateQR />}
          /> 
          <Route
            path={routes.private.downloadQR}
            element={<DownloadQR />}
          /> 
          {/* <Route
            path={routes.private.menuManagement}
            element={<MenuManagement />}
          />  */}
          {/* <Route
            path={routes.private.menuManagement}
            element={<MenuManagement />}
          />  */}


        </Route>

        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
