import React from "react";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
export default function GenerateQR() {
  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="settings" />
      <div className="dashboard-main-content settings-main pb-4">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="row justify-content-center">
                <div className="col-lg-12 mb-4">
                  <h3 className="text-primary">Generate New QR</h3>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-12 mb-4">
                  <div className="shadow bg-white">
                    <div className="top p-3 border-bottom">
                      <h4 className="text-primary">Generate QR</h4>
                    </div>
                    <div className="p-3">
                      <div className="row">

                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="" className="font-weight-semi mb-2">Select Table </label>
                            <select name="" id="" className="form-select">
                              <option value="">Table No 1</option>
                              <option value="">Table No 2</option>
                              <option value="">Table No 3</option>
                              <option value="">Table No 4</option>
                              <option value="">Table No 5</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="" className="font-weight-semi mb-2">Table Location</label>
                            <input type="text" placeholder="Table Location" disabled className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="" className="font-weight-semi mb-2">Table Section</label>
                            <input type="text" placeholder="Table Section" disabled className="form-control" />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3">
                            <label htmlFor="" className="font-weight-semi mb-2">Table Capacity</label>
                            <input type="text" placeholder="Table Capacity" disabled className="form-control" />
                          </div>
                        </div>
                        <div className="col-12 action pt-3 border-top d-flex align-items-center justify-content-end">
                          <button className="btn btn-secondary">
                            Generate
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}
