import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { get_menu_item_lists_user_action } from "../_redux/_actions/menuItemList.action";

function useMenuItemList() {
  const [menuItemList, setMenuItemList] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { menuItemListData } = useSelector(
    (state) => state.get_menu_item_lists_user_reducer
  );

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    if (menuItemListData?.data) {
      setMenuItemList(menuItemListData?.data);
    }
  }, [menuItemListData]);

  const callApi = async () => {
    await dispatch(get_menu_item_lists_user_action());
  };

  const onMenuItemView = (id) => {
    navigate(routes.private.editMenuItem.replace(':id', id))
  };

  return {
    menuItemList,
    onMenuItemView,
  };
}

export default useMenuItemList;
