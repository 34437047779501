export const menuItemConstants = {
    //login
    CREATE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST: "CREATE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST",
    CREATE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS: "CREATE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS",
    CREATE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE: "CREATE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE",
  
    //get MENU_ITEM
    GET_MENU_ITEM_DETAILS_USER_ACTION_REQUEST: "GET_MENU_ITEM_DETAILS_USER_ACTION_REQUEST",
    GET_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS: "GET_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS",
    GET_MENU_ITEM_DETAILS_USER_ACTION_FAILURE: "GET_MENU_ITEM_DETAILS_USER_ACTION_FAILURE",
  
    //ADD_MENU_ITEM
    UPDATE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST: "UPDATE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST",
    UPDATE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS: "UPDATE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS",
    UPDATE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE: "UPDATE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE",
  
    //login
    DELETE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST: "DELETE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST",
    DELETE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS: "DELETE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS",
    DELETE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE: "DELETE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE",
  };
  