import { menuItemConstants } from "../_constants";
import axiosInstance from "../../../../_config/AxiosInstance";
import config from "../../../../_config/config";
// login
export const create_menu_item_details_user_action =
  (data) => async (dispatch) => {
    dispatch({
      type: menuItemConstants.CREATE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST,
    });
    try {
      const response = await axiosInstance.post(
        config.fo.createCategory,
        data
      );
      dispatch({
        type: menuItemConstants.CREATE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: menuItemConstants.CREATE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE,
        payload: e,
      });
      return e;
    }
  };

export const get_menu_item_details_user_action = (id) => async (dispatch) => {
  dispatch({
    type: menuItemConstants.GET_MENU_ITEM_DETAILS_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(
      config.category.categoryByid.replace(":id", id)
    );
    dispatch({
      type: menuItemConstants.GET_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: menuItemConstants.GET_MENU_ITEM_DETAILS_USER_ACTION_FAILURE,
      payload: e,
    });
    return e;
  }
};

export const update_menu_item_details_user_action =
  (id, data) => async (dispatch) => {
    dispatch({
      type: menuItemConstants.UPDATE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST,
    });
    try {
      const response = await axiosInstance.put(
        config.category.categoryByid.replace(":id", id),
        data
      );
      dispatch({
        type: menuItemConstants.UPDATE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: menuItemConstants.UPDATE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE,
        payload: e,
      });
      return e;
    }
  };

export const delete_menu_item_details_user_action = (id) => async (dispatch) => {
  dispatch({
    type: menuItemConstants.DELETE_MENU_ITEM_DETAILS_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.delete(
      config.category.categoryByid.replace(":id", id)
    );
    dispatch({
      type: menuItemConstants.DELETE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: menuItemConstants.DELETE_MENU_ITEM_DETAILS_USER_ACTION_FAILURE,
      payload: e,
    });
    return e;
  }
};
