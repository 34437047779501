import React from "react";
import Header from "../../layouts/Header";
import Sidebar from "../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
export default function MenuManagement() {
  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="menu-management"/>
      <div className="dashboard-main-content menu-managment-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/food-category-list")}
                  >
                    <div className="img">
                      <img src={"./assets/images/food-category.png"} alt="food category" />
                    </div>
                    <h4 className="text-secondary">View Food Categories</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/add-food-category")}
                  >
                    <div className="img">
                      <img src={"./assets/images/add-food-category.png"} alt="food category" />
                    </div>
                    <h4 className="text-secondary">Add Food Category</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/menu-item-list")}
                  >
                    <div className="img">
                      <img src={"./assets/images/menu.png"} alt="food category" />
                    </div>
                    <h4 className="text-secondary">View Food Items</h4>

                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/add-menu-item")}
                  >
                    <div className="img">
                      <img src={"./assets/images/add-food-item.png"} alt="food category" />
                    </div>
                    <h4 className="text-secondary">Add Food Item</h4>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
