import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faClockFour,
  faPencil,
  faPlus,
  faStar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
export const ViewCategoryList = () => {
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);

  const [showDeleteCategory, setShowDeleteCategory] = useState(false);

  const handleDeleteCategoryClose = () => setShowDeleteCategory(false);
  const handleDeleteCategoryShow = () => setShowDeleteCategory(true);

  const handleEditCategoryModalClose = () => setShowEditCategoryModal(false);
  const handleEditCategoryModalShow = () => setShowEditCategoryModal(true);

  const handleRemoveItemModalClose = () => setShowRemoveItemModal(false);

  return (
    <>
      <div className="admin-dashboard-section pg-category-list">
        <Sidebar activeMenuItem="menu-management" />
        <div className="dashboard-main-content">
          <Header pageTitle="Category List" />
          <div className="main-content">
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-md-6">
                        <div className="common-search">
                          <Form>
                            <Form.Group>
                              <Form.Control
                                type="search"
                                className="h-auto py-3 rounded-0 bg-white-0 bg-white"
                                placeholder="Search Category..."
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                      <div className="col-md-3 text-md-end">
                        <button className="btn btn-primary">
                          <FontAwesomeIcon icon={faPlus} className="me-2" />
                          Add New
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white">
                <div className="container py-2 mb-4 border-bottom">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-md-6">
                          <h3>Burgers (12 Items)</h3>
                        </div>
                        <div className="col-md-3 text-md-end">
                          <button
                            className="btn btn-secondary"
                            onClick={handleDeleteCategoryShow}
                          >
                            <FontAwesomeIcon icon={faTrash} className="me-2" />
                            Delete Category
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="food-list-items">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-6 col-lg-4 mb-5">
                        <div className="food-item bg-light shadow-sm">
                          <div className="discount-tag bg-success text-white">
                            25% OFF
                          </div>
                          <div className="special-tag text-white bg-danger">
                            BestSeller
                          </div>
                          <div className="cover-img position-relative">
                            <img
                              src={"/assets/images/food-items/egg-sandwich.jpg"}
                              className="w-100"
                              alt="burgers"
                            />

                            <button
                              className="btn btn-info btn-sm text-white btn-edit-category px-2"
                              onClick={handleEditCategoryModalShow}
                            >
                              <FontAwesomeIcon icon={faPencil} className="me-2" />
                              Change Category
                            </button>
                          </div>
                          <div className="content p-3">
                            <div className="d-flex align-items-center mb-2">
                              <h5 className="food-item-title me-2">
                                Fried Egg Sandwich
                              </h5>

                              <span className="food-type-indicator ms-auto">
                                <img src="../assets/images/veg.png" alt="veg" />
                              </span>
                            </div>
                            <div className="desc">
                              <p>
                                Lorem ipsum dolor sit amet consectetur d elit. Sit,
                                voluptas? Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Minima, sint?
                              </p>
                            </div>
                            <div className="pricing-rating font-weight-bold h5 d-flex align-items-center mb-3">
                              <div className="pricing">
                                <span>$150</span>
                                <span className="discounted-price text-deleted text-danger ms-2 h6">
                                  $200
                                </span>
                              </div>

                              <div className="rating ms-auto d-flex align-items-center font-weight-normal">
                                <span className="text-white bg-primary font-smallest rating-box d-inline-block">
                                  <FontAwesomeIcon icon={faStar} />
                                </span>
                                <span className="font-weight-normal h-6">4.0 </span>
                              </div>
                            </div>
                            <div className="availability-timing d-flex align-items-center">
                              <span className="time ">
                                <FontAwesomeIcon
                                  icon={faClockFour}
                                  className="me-2"
                                />
                                10Mins
                              </span>
                              <span className="ms-auto d-inline-block bg-success text-white rounded-pill px-3">
                                In Stock
                              </span>
                            </div>
                          </div>
                          <div className="actions p-3 text-center">
                            <Link
                              to="/add-menu-item"
                              className="text-center text-secondary  h6  text-uppercase "
                            >
                              Quick View
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-5">
                        <div className="food-item bg-light shadow-sm">
                          <div className="discount-tag bg-success text-white">
                            25% OFF
                          </div>
                          <div className="special-tag text-white bg-danger">
                            BestSeller
                          </div>
                          <div className="cover-img position-relative">
                            <img
                              src={"/assets/images/food-items/chicken-roll.jpg"}
                              className="w-100"
                              alt="burgers"
                            />

                            <button
                              className="btn btn-info btn-sm text-white btn-edit-category px-2"
                              onClick={handleEditCategoryModalShow}
                            >
                              <FontAwesomeIcon icon={faPencil} className="me-2" />
                              Change Category
                            </button>
                          </div>
                          <div className="content p-3">
                            <div className="d-flex align-items-center mb-2">
                              <h5 className="food-item-title me-2">Chicken Roll</h5>

                              <span className="food-type-indicator ms-auto">
                                <img src="../assets/images/non-veg.png" alt="veg" />
                              </span>
                            </div>
                            <div className="desc">
                              <p>
                                Lorem ipsum dolor sit amet consectetur d elit. Sit,
                                voluptas? Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Minima, sint?
                              </p>
                            </div>
                            <div className="pricing-rating font-weight-bold h5 d-flex align-items-center mb-3">
                              <div className="pricing">
                                <span>$150</span>
                                <span className="discounted-price text-deleted text-danger ms-2 h6">
                                  $200
                                </span>
                              </div>

                              <div className="rating ms-auto d-flex align-items-center font-weight-normal">
                                <span className="text-white bg-primary font-smallest rating-box d-inline-block">
                                  <FontAwesomeIcon icon={faStar} />
                                </span>
                                <span className="font-weight-normal h-6">4.0 </span>
                              </div>
                            </div>
                            <div className="availability-timing d-flex align-items-center">
                              <span className="time ">
                                <FontAwesomeIcon
                                  icon={faClockFour}
                                  className="me-2"
                                />
                                10Mins
                              </span>
                              <span className="ms-auto d-inline-block bg-danger text-white rounded-pill px-3">
                                Out of Stock
                              </span>
                            </div>
                          </div>
                          <div className="actions p-3 text-center">
                            <Link
                              to="/add-food-item"
                              className="text-center text-secondary  h6  text-uppercase "
                            >
                              Quick View
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-5">
                        <div className="food-item bg-light shadow-sm">
                          <div className="discount-tag bg-success text-white">
                            25% OFF
                          </div>
                          <div className="special-tag text-white bg-danger">
                            BestSeller
                          </div>
                          <div className="cover-img position-relative">
                            <img
                              src={"/assets/images/food-items/chicken-wings.jpg"}
                              className="w-100"
                              alt="burgers"
                            />

                            <button
                              className="btn btn-info btn-sm text-white btn-edit-category px-2"
                              onClick={handleEditCategoryModalShow}
                            >
                              <FontAwesomeIcon icon={faPencil} className="me-2" />
                              Change Category
                            </button>
                          </div>
                          <div className="content p-3">
                            <div className="d-flex align-items-center mb-2">
                              <h5 className="food-item-title me-2">
                                Chicken Wings
                              </h5>

                              <span className="food-type-indicator ms-auto">
                                <img src="../assets/images/veg.png" alt="veg" />
                              </span>
                            </div>
                            <div className="desc">
                              <p>
                                Lorem ipsum dolor sit amet consectetur d elit. Sit,
                                voluptas? Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Minima, sint?
                              </p>
                            </div>
                            <div className="pricing-rating font-weight-bold h5 d-flex align-items-center mb-3">
                              <div className="pricing">
                                <span>$150</span>
                                <span className="discounted-price text-deleted text-danger ms-2 h6">
                                  $200
                                </span>
                              </div>

                              <div className="rating ms-auto d-flex align-items-center font-weight-normal">
                                <span className="text-white bg-primary font-smallest rating-box d-inline-block">
                                  <FontAwesomeIcon icon={faStar} />
                                </span>
                                <span className="font-weight-normal h-6">4.0 </span>
                              </div>
                            </div>
                            <div className="availability-timing d-flex align-items-center">
                              <span className="time ">
                                <FontAwesomeIcon
                                  icon={faClockFour}
                                  className="me-2"
                                />
                                10Mins
                              </span>
                              <span className="ms-auto d-inline-block bg-success text-white rounded-pill px-3">
                                In Stock
                              </span>
                            </div>
                          </div>
                          <div className="actions p-3 text-center">
                            <Link
                              to="/add-food-item"
                              className="text-center text-secondary  h6  text-uppercase "
                            >
                              Quick View
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4 mb-5">
                        <div className="food-item bg-light shadow-sm">
                          <div className="discount-tag bg-success text-white">
                            25% OFF
                          </div>
                          <div className="special-tag text-white bg-danger">
                            BestSeller
                          </div>
                          <div className="cover-img position-relative">
                            <img
                              src={"/assets/images/food-items/chicken-grill.jpg"}
                              className="w-100"
                              alt="burgers"
                            />

                            <button
                              className="btn btn-info btn-sm text-white btn-edit-category px-2"
                              onClick={handleEditCategoryModalShow}
                            >
                              <FontAwesomeIcon icon={faPencil} className="me-2" />
                              Change Category
                            </button>
                          </div>
                          <div className="content p-3">
                            <div className="d-flex align-items-center mb-2">
                              <h5 className="food-item-title me-2">
                                Chicken Grill
                              </h5>

                              <span className="food-type-indicator ms-auto">
                                <img src="../assets/images/veg.png" alt="veg" />
                              </span>
                            </div>
                            <div className="desc">
                              <p>
                                Lorem ipsum dolor sit amet consectetur d elit. Sit,
                                voluptas? Lorem ipsum dolor sit, amet consectetur
                                adipisicing elit. Minima, sint?
                              </p>
                            </div>
                            <div className="pricing-rating font-weight-bold h5 d-flex align-items-center mb-3">
                              <div className="pricing">
                                <span>$150</span>
                                <span className="discounted-price text-deleted text-danger ms-2 h6">
                                  $200
                                </span>
                              </div>

                              <div className="rating ms-auto d-flex align-items-center font-weight-normal">
                                <span className="text-white bg-primary font-smallest rating-box d-inline-block">
                                  <FontAwesomeIcon icon={faStar} />
                                </span>
                                <span className="font-weight-normal h-6">4.0 </span>
                              </div>
                            </div>
                            <div className="availability-timing d-flex align-items-center">
                              <span className="time ">
                                <FontAwesomeIcon
                                  icon={faClockFour}
                                  className="me-2"
                                />
                                10Mins
                              </span>
                              <span className="ms-auto d-inline-block bg-success text-white rounded-pill px-3">
                                In Stock
                              </span>
                            </div>
                          </div>
                          <div className="actions p-3 text-center">
                            <Link
                              to="/add-food-item"
                              className="text-center text-secondary  h6  text-uppercase "
                            >
                              Quick View
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Category Modal */}
      <Modal
        centered
        size="sm"
        show={showEditCategoryModal}
        onHide={handleEditCategoryModalClose}
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>Change Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="h6">Select from List</p>
          <select name="" id="" className="form-select">
            <option value="Select">Select</option>
            <option value="Momos">Momos</option>
            <option value="Burgers">Burgers</option>
          </select>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button
            variant="secondary"

            onClick={handleEditCategoryModalClose}
          >
            Change
          </Button>
          <Button
            variant="primary"

            onClick={handleEditCategoryModalClose}
          >
            Cancel
          </Button>

        </Modal.Footer>
      </Modal>

      {/* Remove Item from  Category Modal */}
      <Modal
        centered
        size="sm"
        show={showRemoveItemModal}
        onHide={handleRemoveItemModalClose}
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>Remove Item</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="h6">
            Do you really want to remove the item from the category?
          </p>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button
            variant="primary"
            className="btn-sm"
            onClick={handleRemoveItemModalClose}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            className="btn-sm"
            onClick={handleRemoveItemModalClose}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>

      {/* delele cateogry Modal */}
      <Modal
        centered
        size="sm"
        show={showDeleteCategory}
        onHide={handleDeleteCategoryClose}
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="h6">Do you really want to delete this category?</p>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button variant="secondary" onClick={handleDeleteCategoryClose}>
            Delete
          </Button>
          <Button variant="primary" onClick={handleDeleteCategoryClose}>
            Cancel
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};
