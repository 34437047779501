import React, { useState } from "react";
import { Modal, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faEye, faTrash, faPlus } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
export const TableList = () => {
  const [deletePopupModal, setDeletePopupModal] = useState(false);
  const [viewTableModal, SetViewTableModal] = useState(false);

  const handleDeletePopupModalClose = () => setDeletePopupModal(false);
  const handleDeletePopupModalShow = () => setDeletePopupModal(true);

  const handleViewTableModalClose = () => SetViewTableModal(false);
  const handleViewTableModalShow = () => SetViewTableModal(true);

  return (
    <div className="admin-dashboard-section pg-category-list">
      <Sidebar activeMenuItem="table-management" />
      <div className="dashboard-main-content tables-wapper">
        <Header />
        <div className="main-content">
          <div className="container">
            <div className="page-body">
              <div className="row justify-content-between align-items-center mb-4">
                <div className="col-md-6">
                  <div className="common-search">
                    <Form>
                      <Form.Group>
                        <Form.Control
                          type="search"
                          className="h-auto py-3 rounded-0 bg-white"
                          placeholder="Search Table..."
                        />
                      </Form.Group>
                    </Form>
                  </div>
                </div>
                <div className="col-md-3 text-md-end">
                  <Link to="/add-food-item" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPlus} className="me-2" />
                    Add New
                  </Link>
                </div>
              </div>

              <h3 className="mb-2">Table List </h3>
              <div className="tables-list mt-3 bg-white p-3 ">
                <Table striped responsive bordered hover>
                  <thead>
                    <tr>
                      <th>Sl No.</th>
                      <th>Table Number</th>
                      <th>Table Location</th>
                      <th>Section</th>
                      <th>Capacity</th>
                      <th>View</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>Table No.1</td>
                      <td>First Floor</td>
                      <td>Couples </td>
                      <td>10 Seats</td>
                      <td>
                        <span
                          className="btn-view cursor-pointer text-success font-weight-semi"
                          onClick={handleViewTableModalShow}
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                          View
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn-delete cursor-pointer text-danger font-weight-semi"
                          onClick={handleDeletePopupModalShow}
                        >
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Delete
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>Table No.2</td>
                      <td>First Floor</td>
                      <td>Family </td>
                      <td>10 Seats</td>
                      <td>
                        <span
                          className="btn-view cursor-pointer text-success font-weight-semi"
                          onClick={handleViewTableModalShow}
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                          View
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn-delete cursor-pointer text-danger font-weight-semi"
                          onClick={handleDeletePopupModalShow}
                        >
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Delete
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>Table No.3</td>
                      <td>First Floor</td>
                      <td>Couples </td>
                      <td>10 Seats</td>
                      <td>
                        <span
                          className="btn-view cursor-pointer text-success font-weight-semi"
                          onClick={handleViewTableModalShow}
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                          View
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn-delete cursor-pointer text-danger font-weight-semi"
                          onClick={handleDeletePopupModalShow}
                        >
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Delete
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>Table No.4</td>
                      <td>First Floor</td>
                      <td>Family </td>
                      <td>10 Seats</td>
                      <td>
                        <span
                          className="btn-view cursor-pointer text-success font-weight-semi"
                          onClick={handleViewTableModalShow}
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                          View
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn-delete cursor-pointer text-danger font-weight-semi"
                          onClick={handleDeletePopupModalShow}
                        >
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Delete
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>Table No.5</td>
                      <td>First Floor</td>
                      <td>Couples </td>
                      <td>10 Seats</td>
                      <td>
                        <span
                          className="btn-view cursor-pointer text-success font-weight-semi"
                          onClick={handleViewTableModalShow}
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                          View
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn-delete cursor-pointer text-danger font-weight-semi"
                          onClick={handleDeletePopupModalShow}
                        >
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Delete
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>Table No.6</td>
                      <td>First Floor</td>
                      <td>Family </td>
                      <td>10 Seats</td>
                      <td>
                        <span
                          className="btn-view cursor-pointer text-success font-weight-semi"
                          onClick={handleViewTableModalShow}
                        >
                          <FontAwesomeIcon icon={faEye} className="me-2" />
                          View
                        </span>
                      </td>
                      <td>
                        <span
                          className="btn-delete cursor-pointer text-danger font-weight-semi"
                          onClick={handleDeletePopupModalShow}
                        >
                          <FontAwesomeIcon icon={faTrash} className="me-2" />
                          Delete
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* delete table popup */}
      <Modal
        size="sm"
        show={deletePopupModal}
        onHide={handleDeletePopupModalClose}
        centered
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title>
            <h4 className="text-secondary"> Delete table</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <p>Do you really want to delete this table?</p>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button
            className="btn btn-primary"
            onClick={handleDeletePopupModalClose}
          >
            Cancel
          </button>
          <button className="btn btn-danger">Delete</button>
        </Modal.Footer>
      </Modal>
      {/* delete table popup ends */}

      {/* view table details */}
      <Modal
        size="lg"
        show={viewTableModal}
        onHide={handleViewTableModalClose}
        centered
        className="view-table-modal"
      >
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          <Modal.Title>
            {/* <h4 className="text-secondary">View Staff</h4> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-0">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6  d-flex align-items-center justify-content-center text-white staff-brief-wrapper ">
                <div className="staff-brief p-1 text-center">
                  <div className="staff-img ">
                    <img src={"/assets/images/table-logo.png"} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <h3 className="text-secondary mb-3">Table Details</h3>
                <div className="row">
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-secondary mb-1">
                      Table Number
                    </p>
                    <p className="fs-small mb-1">1</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p className="font-weight-semi text-secondary mb-1">
                      Capacity(Seats)
                    </p>
                    <p className="fs-small mb-1">8 People</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    {" "}
                    <p className="font-weight-semi text-secondary mb-1">
                      Location
                    </p>
                    <p className="fs-small mb-1">1st Floor</p>{" "}
                  </div>
                  <div className="col-lg-6">
                    <p className="font-weight-semi text-secondary mb-1">
                      Special Section
                    </p>
                    <p className="fs-small mb-1">Couples Section</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-end border-top-0">
          <button className="btn btn-success">Edit</button>
          <button
            className="btn btn-danger"
            onClick={handleDeletePopupModalShow}
          >
            Delete
          </button>
          <button
            className="btn btn-primary"
            onClick={handleViewTableModalClose}
          >
            close
          </button>
        </Modal.Footer>
      </Modal>
      {/* view table details */}
    </div>
  );
};
