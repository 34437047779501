import React, { useState } from "react";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import { Form, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faClockFour,
  faPencil,
  faPlus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import useMenuItemList from "./useMenuItemList";
export const MenuItemList = (props) => {

  const {
    menu
  } = useMenuItemList(props)

  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);

  const handleEditCategoryModal = () => setShowEditCategoryModal(val => !val);

  return (
    <>
      <div className="admin-dashboard-section pg-category-list">
        <Sidebar activeMenuItem="menu-management" />
        <div className="dashboard-main-content">
          <Header pageTitle="Category List" />
          <div className="main-content">
            <div className="container">
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="">
                    <div className="row justify-content-between align-items-center">
                      <div className="col-md-6">
                        <div className="common-search">
                          <Form>
                            <Form.Group>
                              <Form.Control
                                type="search"
                                className="h-auto bg-white py-3 rounded-0"
                                placeholder="Search Food Item..."
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                      <div className="col-md-3 text-md-end">
                        <Link to="/add-menu-item" className="btn btn-primary">
                          <FontAwesomeIcon icon={faPlus} className="me-2" />
                          Add New
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white">
                <div className="filters border-bottom p-3 bg-white">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <p className="font-weight-semi text-nowrap me-3 mb-0">
                        Filter By
                      </p>
                    </div>
                    <div className="col-md-6">
                      <div className="category d-flex align-items-center justify-content-end">
                        <p className="font-weight-semi text-nowrap me-3 mb-0">
                          Select Category
                        </p>
                        <select className="rounded h-auto p-2">
                          <option value="">All</option>
                          <option value="">Burgers</option>
                          <option value="">Chinese</option>
                          <option value="">Continental</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="food-items-list-wrapper bg-white p-4 rounded ">
                  <div className="food-item-box mb-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-4">
                          <div className="row justify-content-between align-items-center">
                            <div className="col-md-6">
                              <h3>Burgers (12 Items)</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="food-list-items">
                      <div className="row">
                        {<div className="col-md-6 col-lg-4 mb-4">
                          <div className="food-item bg-white shadow-sm bg-light">
                            <div className="discount-tag bg-success text-white">
                              25% OFF
                            </div>
                            <div className="special-tag text-white bg-danger">
                              BestSeller
                            </div>
                            <div className="cover-img position-relative">
                              <img
                                src={"/assets/images/food-items/egg-sandwich.jpg"}
                                className="w-100"
                                alt="burgers"
                              />

                              <button
                                className="btn btn-info btn-sm text-white btn-edit-category px-2"
                                onClick={handleEditCategoryModal}
                              >
                                <FontAwesomeIcon
                                  icon={faPencil}
                                  className="me-2"
                                />
                                Change Category
                              </button>
                            </div>
                            <div className="content p-3">
                              <div className="d-flex align-items-center mb-2">
                                <h5 className="food-item-title me-2 text-primary">
                                  Fried Egg Sandwich
                                </h5>

                                <span className="food-type-indicator ms-auto">
                                  <img src="assets/images/veg.png" alt="veg" />
                                </span>
                              </div>
                              <div className="desc">
                                <p>
                                  Lorem ipsum dolor sit amet consectetur d elit.
                                  Sit, voluptas? Lorem ipsum dolor sit, amet
                                  consectetur adipisicing elit. Minima, sint?
                                </p>
                              </div>
                              <div className="pricing-rating font-weight-bold h5 d-flex align-items-center mb-3">
                                <div className="pricing">
                                  <span>$150</span>
                                  <span className="discounted-price text-deleted text-danger ms-2 h6">
                                    $200
                                  </span>
                                </div>

                                <div className="rating ms-auto d-flex align-items-center font-weight-normal">
                                  <span className="text-white bg-success primary font-smallest rating-box d-inline-block">
                                    <FontAwesomeIcon icon={faStar} className="text-white" />
                                  </span>
                                  <span className="font-weight-medium h-6 text-success">
                                    4.0{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="availability-timing d-flex align-items-center">
                                <span className="time ">
                                  <FontAwesomeIcon
                                    icon={faClockFour}
                                    className="me-2"
                                  />
                                  10Mins
                                </span>
                                <span className="ms-auto d-inline-block bg-success text-white rounded-pill px-3">
                                  In Stock
                                </span>
                              </div>
                            </div>
                            <div className="actions p-3 text-center">
                              <Link
                                to="/add-menu-item"
                                className="btn-secondary btn text-center text-primary  text-uppercase "
                              >
                                Quick View
                              </Link>
                            </div>
                          </div>
                        </div>}


                        {/* <div className="col-md-6 col-lg-4 mb-5">
                        <div className="food-item bg-white shadow-sm">
                          <div className="discount-tag bg-success text-white">
                            25% OFF
                          </div>
                          <div className="special-tag text-white bg-danger">
                            BestSeller
                          </div>
                          <div className="cover-img position-relative">
                            <img
                              src={
                                "/assets/images/food-items/chicken-grill.jpg"
                              }
                              className="w-100"
                              alt="burgers"
                            />

                            <button
                              className="btn btn-info btn-sm text-white btn-edit-category px-2"
                              onClick={handleEditCategoryModalShow}
                            >
                              <FontAwesomeIcon
                                icon={faPencil}
                                className="me-2"
                              />
                              Change Category
                            </button>
                          </div>
                          <div className="content p-3">
                            <div className="d-flex align-items-center mb-2">
                              <h5 className="food-item-title me-2">
                                Chicken Grill
                              </h5>

                              <span className="food-type-indicator ms-auto">
                                <img src="assets/images/veg.png" alt="veg" />
                              </span>
                            </div>
                            <div className="desc">
                              <p>
                                Lorem ipsum dolor sit amet consectetur d elit.
                                Sit, voluptas? Lorem ipsum dolor sit, amet
                                consectetur adipisicing elit. Minima, sint?
                              </p>
                            </div>
                            <div className="pricing-rating font-weight-bold h5 d-flex align-items-center mb-3">
                              <div className="pricing">
                                <span>$150</span>
                                <span className="discounted-price text-deleted text-danger ms-2 h6">
                                  $200
                                </span>
                              </div>

                              <div className="rating ms-auto d-flex align-items-center font-weight-normal">
                                <span className="text-white bg-primary font-smallest rating-box d-inline-block">
                                  <FontAwesomeIcon icon={faStar} />
                                </span>
                                <span className="font-weight-normal h-6">
                                  4.0{" "}
                                </span>
                              </div>
                            </div>
                            <div className="availability-timing d-flex align-items-center">
                              <span className="time ">
                                <FontAwesomeIcon
                                  icon={faClockFour}
                                  className="me-2"
                                />
                                10Mins
                              </span>
                              <span className="ms-auto d-inline-block bg-success text-white rounded-pill px-3">
                                In Stock
                              </span>
                            </div>
                          </div>
                          <div className="actions p-3 text-center">
                            <Link
                              to="/add-food-item"
                              className="text-center text-primary  h6  text-uppercase "
                            >
                              Quick View
                            </Link>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Category Modal */}
      <Modal
        centered
        size="sm"
        show={showEditCategoryModal}
        onHide={handleEditCategoryModal}
      >
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>Change Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <p className="h6">Select from List</p>
          <select name="" id="" className="form-select">
            <option value="Select">Select</option>
            <option value="Momos">Momos</option>
            <option value="Burgers">Burgers</option>
          </select>
        </Modal.Body>
        <Modal.Footer className="border-top-0">
          <Button
            variant="secondary"

            onClick={handleEditCategoryModal}
          >
            Change
          </Button>
          <Button
            variant="primary"
            onClick={handleEditCategoryModal}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
