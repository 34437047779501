import { menuItemConstants } from "../_constants";


const initialState ={};

//login reducer
export const get_menu_item_details_user_reducer = (state = initialState, action) => {
    switch (action.type) {
        
        case menuItemConstants.CREATE_MENU_ITEM_USER_ACTION_REQUEST:
            return {
                type: "alert-success",
                message: action.payload,
            };
        case menuItemConstants.CREATE_MENU_ITEM_USER_ACTION_SUCCESS:
        case menuItemConstants.GET_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS:
        case menuItemConstants.UPDATE_MENU_ITEM_DETAILS_USER_ACTION_SUCCESS:
            return {
                loggedIn: true,
                menuItemDetailsData: action.payload
            }
        case menuItemConstants.CREATE_CATEGORY_USER_ACTION_FAILURE:
            return {
                type: "alert-failure",
                errorCategory: action.payload
            }
        default:
            return state;
    }
};