import React, { useState } from "react";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import { Modal, Tab, Table, Tabs, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function ViewInventory() {

  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="staff-management" />
      <div className="dashboard-main-content staff-listing-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <Tabs
            defaultActiveKey="All"
            className="custom-tabs"
          >
            <Tab eventKey="All" title="All">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
                  <h4 className="text-primary">All Items</h4>
                  <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-inventory-item") }}>+ Add New Item</button>
                </div>
                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th className="text-nowrap">Item Id</th>
                        <th className="text-nowrap">
                          Item Name</th>
                        <th className="text-nowrap">Item Quantity </th>
                        <th>Status</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>10</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-success me-2" />
                          Surplus</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>

                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>0</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-danger me-2" />
                          Stock Out</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>

                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>2</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-warning me-2" />
                          Running out  </td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>

                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Surplus" title="Surplus Items">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
                  <h4 className="text-primary">Surplus Items</h4>
                  <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-inventory-item") }}>+ Add New Item</button>
                </div>
                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th className="text-nowrap">Item Id</th>
                        <th className="text-nowrap">
                          Item Name</th>
                        <th className="text-nowrap">Item Quantity </th>
                        <th>Status</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>10</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-success me-2" />
                          Surplus</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>

                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>10</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-success me-2" />
                          Surplus</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>10</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-success me-2" />
                          Surplus</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>

                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="RunningOut" title="Running Out">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
                  <h4 className="text-primary">Running Out Items</h4>
                  <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-inventory") }}>+ Add New Item</button>
                </div>
                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th className="text-nowrap">Item Id</th>
                        <th className="text-nowrap">
                          Item Name</th>
                        <th className="text-nowrap">Item Quantity </th>
                        <th>Status</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>2</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-warning me-2" />
                          Running out  </td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>2</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-warning me-2" />
                          Running out  </td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>2</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-warning me-2" />
                          Running out  </td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>
            <Tab eventKey="StockOut" title="Stock Out">
              <div className="bg-white px-3 shadow pb-3 mb-5">
                <div className="mb-3 py-3 border-bottom d-flex align-items-center justify-content-between">
                  <h4 className="text-primary">Stock Out Items Inventory</h4>
                  <button className="ms-auto btn btn-primary" onClick={() => { navigate("/add-inventory") }}>+ Add New Item</button>
                </div>
                <div className="custom-table-wrapper border">
                  <Table responsive hover className="custom-table" >
                    <thead>
                      <tr>
                        <th className="text-nowrap">Item Id</th>
                        <th className="text-nowrap">
                          Item Name</th>
                        <th className="text-nowrap">Item Quantity </th>
                        <th>Status</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>0</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-danger me-2" />
                          Stock Out</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>0</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-danger me-2" />
                          Stock Out</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>0</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-danger me-2" />
                          Stock Out</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>
                      <tr>
                        <td>4466</td>
                        <td>
                          Coffee
                        </td>
                        <td>0</td>
                        <td>
                          <FontAwesomeIcon icon={faCircle} className="text-danger me-2" />
                          Stock Out</td>
                        <td>
                          <button className="btn btn-primary btn-sm"
                            onClick={() => { navigate("/add-inventory-item") }}
                          >View</button>
                        </td>
                      </tr>


                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>

          </Tabs>

        </div>
      </div>
    </div>
  );
}
