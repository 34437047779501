import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import {
  faPlus,
  faStopwatch,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import { useNavigate } from "react-router-dom";
export const TableDashboard = () => {
  const navigate = useNavigate();
  return (
    <div className="table-dashboard-main admin-dashboard-section">
      <Sidebar activeMenuItem="table-management" />
      <div className="dashboard-main-content tables-wapper">
        <Header />
        <div className="container">
          <div className="row justify-content-center pt-lg-5">

            <div className="col-lg-8">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/table-list")}
                  >
                    <div className="img">
                      <img src={"./assets/images/table-listing.png"} alt="staff listing" />
                    </div>
                    <h4 className="text-secondary">View Table Listing</h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/add-table")}
                  >
                    <div className="img">
                      <img src={"./assets/images/add-table.png"} alt="add-staff" />
                    </div>
                    <h4 className="text-secondary">Add New Table </h4>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="bg-white shadow border p-4 text-center cursor-pointer menu-managment-box mb-4"
                    onClick={() => navigate("/table-management")}
                  >
                    <div className="img">
                      <img src={"./assets/images/manage-table.png"} alt="add-staff" />
                    </div>
                    <h4 className="text-secondary">Manage Tables </h4>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>


    </div>
  );
};
