import React, { useState } from "react";
import Header from "../../../layouts/Header";
import Sidebar from "../../../layouts/Sidebar";
import { Modal, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
export default function AddStaff() {

  const navigate = useNavigate();
  return (
    <div className="admin-dashboard-section">
      <Sidebar activeMenuItem="staff-management" />
      <div className="dashboard-main-content staff-listing-main">
        <Header pageTitle="Dashboard" />
        <div className="container">
          <div className="form-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-10 mb-4">
                <h3 className="text-primary">Add New Team Member</h3>
              </div>
            </div>
            <Form>
              <div className="row justify-content-center">
                <div className="col-lg-10 ">
                  <div className="bg-white shadow rounded-lg p-3 mb-4">
                    <div class="border-bottom pb-3 mb-4">
                      <h5 className="text-primary"> Basic Information</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3 img-upload-wrapper">
                          <label htmlFor="" className="font-weight-semi mb-2 d-block">Upload Memeber's Photo</label>
                          <label htmlFor="RestaurantLogo" className="img-upload bg-grey p-3">
                            <input type="file" id="RestaurantLogo" className="d-none" />
                            <img src="./assets/images/photo-placeholder.png" alt="placeholder" />
                          </label>
                          <p className="">png, jpg files only...</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Employee Id
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="0001"
                            disabled
                          />
                        </Form.Group>

                      </div>
                      <div className="col-lg-6">

                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Employee Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Employeee Name"
                          />
                        </Form.Group>

                      </div>
                      <div className="col-lg-6">

                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Employee Designation{" "}
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Employee Designation"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white shadow rounded-lg p-3 mb-4">
                    <div class="border-bottom pb-3 mb-4">
                      <h5 className="text-primary"> Personal Information</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Contact number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Contact number"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Emergency Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Emergency Contact Number"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Date of Birth (Age)
                          </Form.Label>
                          <Form.Control
                            type="date"
                            className="form-control"
                            placeholder="Item Name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Email Id
                          </Form.Label>
                          <Form.Control
                            type="email"
                            className="form-control"
                            placeholder="Email Id"
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-12">
                        <h5 className="mb-2">Address</h5>
                      </div>

                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Street Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Street Name"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Country
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="form-control"
                          >
                            <option value="">Select</option>
                            <option value="Quater">India</option>
                            <option value="Half">Pakistan</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            City
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="form-control"
                          >
                            <option value="">Select</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Mumbai">Mumbai</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Pin Code
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            placeholder="Pin Code"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-12">
                        <Form.Label className="mb-2 font-weight-semi pl-2 font-weight-semi">
                          Address Proof
                        </Form.Label>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Proof Type
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="form-control"
                          >
                            <option value="">Select</option>
                            <option value="Delhi">Adhaar</option>
                            <option value="Mumbai">Voter</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            ID Number
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="ID Number"
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white shadow rounded-lg p-3 mb-4">
                    <div class="border-bottom pb-3 mb-4">
                      <h5 className="text-primary">Experience</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Job Title
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="Job Title"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Employer Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="Employer Name"
                          />
                        </Form.Group>
                      </div>

                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Country
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="h-auto p-3 px-3  bg-light"
                          >
                            <option value="">Select</option>
                            <option value="Quater">India</option>
                            <option value="Half">Pakistan</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            City
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="h-auto p-3 px-3  bg-light"
                          >
                            <option value="">Select</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Mumbai">Mumbai</option>
                          </Form.Control>
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Start Date
                          </Form.Label>
                          <Form.Control
                            type="date"
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="Start Date"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-6">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            End Date
                          </Form.Label>
                          <Form.Control
                            type="date"
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="End Date"
                          />
                        </Form.Group>
                      </div>
                      <div className="col-lg-12">
                        <Form.Group className="mb-3">
                          <Form.Label className="mb-2 font-weight-semi pl-2">
                            Achievements and Responsibilities
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            className="h-auto p-3 px-3  bg-light"
                            placeholder="Achievements and Responsibilities..."
                          />
                        </Form.Group>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <Form.Group className="mb-3">
                        <button className="btn btn-secondary btn-sm">
                          +  Add More
                        </button>
                      </Form.Group>
                    </div>
                    <div className="col-12 action pt-3 border-top d-flex align-items-center justify-content-end">
                      <button className="btn btn-secondary">
                        Submit
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
