import React from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImages, faUserPlus, faVideo } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../../layouts/Sidebar";
import Header from "../../../layouts/Header";
import useAddMenuItem from "./useAddMenuItem";
export const AddMenuItem = (props) => {
  const {
    menuItemDetailsForm,

    onFormDataChange,
    onMenuItemDelete,
    onFormSubmit,
    onEditClose,
  } = useAddMenuItem(props);

  return (
    <>
      <div className="admin-dashboard-section pg-add-food-item pb-5">
        <Sidebar activeMenuItem="menu-management" />
        <div className="dashboard-main-content">
          <Header pageTitle="Add Food Item" />
          <div className="form-wrapper">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-10 mb-4">
                  <h3 className="text-primary">Add New Food Item</h3>
                </div>
              </div>
              <Form>
                <div className="row justify-content-center">
                  <div className="col-lg-10 ">
                    <div className="bg-white shadow rounded-lg p-3 mb-4">
                      <div class="border-bottom pb-3 mb-4">
                        <h5 className="text-primary"> Food Item Information</h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Item Id</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder=""
                              value="021245"
                              disabled
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Item Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Item Name"
                              name="name"
                              defaultValue={menuItemDetailsForm?.name}
                              onChange={onFormDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Item Price (in Rs)</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Enter Item Price"
                              name="price"
                              defaultValue={menuItemDetailsForm?.price}
                              onChange={onFormDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Food Category</Form.Label>
                            <Form.Select className="form-control">
                              <option>Select</option>
                              <option value="1">Burgers</option>
                              <option value="2">Pizzas</option>
                              <option value="3">Grills</option>
                              <option value="3">Chinese</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Item Type</Form.Label>
                            <Form.Select className="form-control">
                              <option>Select</option>
                              <option value="1">Veg</option>
                              <option value="2">Non Veg</option>
                              <option value="3">Alcoholic</option>
                              <option value="3">Non Alcoholic</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-lg-6">
                          <Form.Group className="mb-3">
                            <Form.Label>Item Quantity</Form.Label>
                            <Form.Select className="form-control">
                              <option>Select</option>
                              <option value="1">Quater</option>
                              <option value="2">Half</option>
                              <option value="3">Full</option>
                              <option value="3">30 Ml</option>
                              <option value="3">60 Ml</option>
                              <option value="3">180 Ml</option>
                              <option value="3">375 Ml</option>
                              <option value="3">750 Ml</option>
                              <option value="3">1 Ltr</option>
                            </Form.Select>
                          </Form.Group>
                        </div>
                        <div className="col-lg-12">
                          <Form.Group className="mb-3">
                            <Form.Label>Item Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Description"
                              name="description"
                              defaultValue={menuItemDetailsForm?.description}
                              onChange={onFormDataChange}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>


                    <div className="bg-white shadow rounded-lg p-3 mb-4">
                      <div class="border-bottom pb-3 mb-4">
                        <h5 className="text-primary">Additonal Information</h5>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group mb-3 img-upload-wrapper">
                            <label htmlFor="" className="font-weight-semi mb-2 d-block">Upload Food Image</label>
                            <label htmlFor="RestaurantLogo" className="img-upload bg-grey p-3">
                              <input type="file" id="RestaurantLogo" className="d-none" />
                              <img src="./assets/images/photo-placeholder.png" alt="placeholder" />
                            </label>
                            <p className="">png, jpg files only...</p>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group mb-3 img-upload-wrapper">
                            <label htmlFor="" className="font-weight-semi mb-2 d-block">Upload Short Video</label>
                            <label htmlFor="RestaurantLogo" className="img-upload bg-grey p-3">
                              <input type="file" id="RestaurantLogo" className="d-none" />
                              <img src="./assets/images/video-camera.png" alt="placeholder" />
                            </label>
                            <p className="">mp4, mpeg files only...</p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <h6 className="mb-2 pb-2 border-bottom">Add Ons</h6>
                        </div>
                        <div className="col-lg-4">
                          <Form.Group className="mb-3">
                            <Form.Label>Add On Category Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Add On"
                            />

                          </Form.Group>
                        </div>
                        <div className="col-lg-4">
                          <Form.Group className="mb-3">
                            <Form.Label>Add On Item Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter Add On"
                            />

                          </Form.Group>
                        </div>
                        <div className="col-lg-4">
                          <Form.Group className="mb-3">
                            <Form.Label>Add On Item Price</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Price in (Rs)"
                            />
                          </Form.Group>
                        </div>

                        <div className="col-lg-12">
                          <Form.Group className="mb-3">
                            <button className="btn btn-primary btn-sm">
                              + Add More
                            </button>
                          </Form.Group>
                        </div>
                        <div className="col-lg-4">
                          <Form.Group className="mb-3">
                            <Form.Label>Enable Discount</Form.Label>
                            <Form.Check
                              type="switch"
                              id="EnableDiscount"
                              label="On/Off"
                              defaultChecked="checked"
                            />{" "}
                          </Form.Group>
                        </div>
                        <div className="col-lg-4">
                          <Form.Group className="mb-3">
                            <Form.Label>Discount Offer</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="in (%)"
                              max={100}
                              name="name"
                              defaultValue={menuItemDetailsForm?.discount}
                              onChange={onFormDataChange}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-lg-4">
                          <Form.Group className="mb-3">
                            <Form.Label>Availablity of the item</Form.Label>
                            <Form.Check
                              type="switch"
                              id="EnableAvailability"
                              label="On/Off"
                            />{" "}
                          </Form.Group>
                        </div>

                        <div className="col-lg-12 action d-flex align-items-center justify-content-end pt-3 border-top">
                          <button
                            className="btn btn-secondary w-20"
                            onClick={onFormSubmit}
                          >
                            Submit
                          </button>
                          {menuItemDetailsForm?.id && (
                            <button
                              className="btn btn-danger w-20 ms-3"
                              onClick={onMenuItemDelete}
                            >
                              Delete
                            </button>
                          )}
                          <button
                            className="btn btn-primary btn-disabled w-20 ms-3"
                            onClick={onEditClose}
                          >
                            Close
                          </button>
                        </div>
                       

                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
