import { menuItemListsConstants } from "../_constants";
import axiosInstance from "../../../../_config/AxiosInstance";
import config from "../../../../_config/config";
// login
export const get_menu_item_lists_user_action = (data) => async (dispatch) => {
  dispatch({
    type: menuItemListsConstants.GET_MENU_ITEM_LISTS_USER_ACTION_REQUEST,
  });
  try {
    const response = await axiosInstance.get(config.menuItems.menuItemLists);
    dispatch({
      type: menuItemListsConstants.GET_MENU_ITEM_LISTS_USER_ACTION_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    dispatch({
      type: menuItemListsConstants.GET_MENU_ITEM_LISTS_USER_ACTION_FAILURE,
      payload: e,
    });
    return e;
  }
};
